import { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink, NavLink as RouterLink, matchPath, useLocation, useNavigate } from 'react-router-dom';
// material
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, List, Collapse, ListItemText, ListItemIcon, ListItemButton, useMediaQuery, Avatar, Button } from '@mui/material';
//
import Iconify from './Iconify';

import edjobster09 from "../assets/images/edjobster09.png";

// ----------------------------------------------------------------------

const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
};

function NavItem({ item, active }) {
  const theme = useTheme();

  const isActiveRoot = active(item.path);
  const { title, path, icon, info, children } = item;

  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
  };

  const activeSubStyle = {
    color: 'text.primary',
    fontWeight: 'fontWeightMedium',
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          <ListItemText disableTypography primary={title} />
          {info && info}
          <Box display={'flex'} alignItems={'center'} marginRight={2}>
            <Iconify
              icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
              sx={{ width: 16, height: 16, ml: 1 }}
            />
          </Box>
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item) => {
              const { title, path } = item;
              const isActiveSub = active(path);

              return (
                <ListItemStyle
                  key={title}
                  component={RouterLink}
                  to={path}
                  sx={{
                    ...(isActiveSub && activeSubStyle),
                  }}
                >
                  <ListItemIconStyle>
                    <Box
                      component="span"
                      sx={{
                        width: 4,
                        height: 4,
                        display: 'flex',
                        borderRadius: '50%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: 'text.disabled',
                        transition: (theme) => theme.transitions.create('transform'),
                        ...(isActiveSub && {
                          transform: 'scale(2)',
                          bgcolor: 'primary.main',
                        }),
                      }}
                    />
                  </ListItemIconStyle>
                  <ListItemText disableTypography primary={title} />
                </ListItemStyle>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      <ListItemText disableTypography primary={title} />
      {info && info}
    </ListItemStyle>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array,
  mainConfig: PropTypes.array,
};

export default function NavSection({ navConfig, mainConfig, ...other }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isMobileOrTablet = useMediaQuery((theme) => theme.breakpoints.down('md'));


  const getLogoWidth = () => {
    if (isMobileOrTablet) return '100px';
    return '120px';
  };

  const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);

  const userInfo = JSON.parse(localStorage.getItem("globalUser")).account

  const removeAllCookies = () => {
    document.cookie.split(";").forEach((cookie) => {
      const name = cookie.split("=")[0].trim();
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    });
  };
  const handleLogOut =() =>{

    localStorage.clear()
    sessionStorage.clear()
    removeAllCookies()

    navigate('/login')
  }

  return (
    <>
    <Box {...other}>
        {isMobileOrTablet && (
          <NavLink to="/dashboard/app" className="header-logo">
          <Box display="flex" justifyContent="center" alignItems="center" width="100%">
          <img src={edjobster09} alt="logo" style={{ width: getLogoWidth() }} />
        </Box>
          </NavLink>
        )}
       <List disablePadding sx={{ p: 1, mt: 1 }}>
        {mainConfig?.map((item, index) => (
          <li key={index + 1}>
            <NavItem key={item.title} item={item} active={match} />
          </li>
        ))}
        {navConfig.map((item, index) => (
          <li key={index + 1}>
            <NavItem key={item.title} item={item} active={match} />
          </li>
        ))}
      </List>
    </Box>
      {isMobileOrTablet && (
        <>
        <Box sx={{ p: 2, bgcolor: 'background.default', borderTop: '1px solid', borderColor: 'divider', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
          <Box sx={{ mb: 1, fontWeight: 'bold' }}>{userInfo.first_name} {userInfo.last_name}</Box>
          <Avatar alt="photoURL" />
          <Box sx={{ color: 'text.secondary' }}>{userInfo.email}</Box>
        </Box>
        <Button
          onClick={handleLogOut}
          sx={{
            bgcolor: 'primary.main', // Change the background color
            color: 'white',
            justifyContent: 'center',
            width: '100%', // Optional: to make the button full width
            display: 'flex', // Added to enable flexbox
            alignItems: 'center',
            textAlign: 'center',
            '&:hover': {
              bgcolor: 'primary.main', // Set hover background color to primary
              opacity: 0.8, // Optional: adjust opacity on hover
            },
          }}
        >
            {/* <Iconify icon="eva:log-out-fill" />  */}
          <ListItemText disableTypography primary="Logout" />
        </Button>
        </>
      )}
    </>
  );
}
