import React, { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { Link as RouterLink, useParams, useLocation, useNavigate, Link } from 'react-router-dom';
import './create-assesment.css';

import Grid from '@mui/material/Grid';

// material
import { Card, Button, Container, Stack, Typography, FormControl, Select, MenuItem, InputLabel, Checkbox, Box, TextareaAutosize, Tooltip, InfoIcon, ListItemIcon, Modal, useMediaQuery } from '@mui/material';
import TextField from '@mui/material/TextField';
import PreviewAssesment from './PreviewAssesment';
import { showToast } from '../../../utils/toast';

// import {useGetAssesmentCategoryQuery } from '../../../redux/services/main/AssesmentCatagoriesservice'
import { useAddAssesmentMutation, useUpdateAssesmentMutation } from '../../../redux/services/main/AssesmentService';
import {
  useGetAssesmentQuestionsQuery,
  useAddAssesmentQuestionsMutation,
  useDeleteAssesmentQuestionsMutation,
} from '../../../redux/services/main/AssesmentQuestionsService';
import { useGetAssesmentCategoryQuery, useUpdateAssesmentCategoryMutation } from '../../../redux/services/main/AssesmentCatagoriesservice';
import { useGetCompanyInfoQuery } from "../../../redux/services/settings/CareerSiteService"
import Iconify from '../../../components/Iconify';
import CandidateSettingModal from "../../settings/candidate-settings/CandidateSettingsModal";
// import AssesmentPreview from './AssesmentPreview';
import vector from "../../../assets/images/backward.png"
import Back from '../../../assets/images/back.svg';


const CreateAssessment = ({ isModal, onClose }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { state } = useLocation();
  const [editmodalOpen, setEditModalOpen] = useState(false);
  const [editValue, setEditValue] = useState({
    id: undefined,
    name: '',
  });
  const [showComponent, setShowComponent] = useState(false);
  const [showOtherComponent, setShowOtherComponent] = useState(true);
  const [checked, setChecked] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [btnLoader, setBtnLoader] = useState(false);
  const navigate = useNavigate()
  const [modalOpen, setModalOpen] = useState(false);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const { id: assessmentEditId } = useParams();
  const [updateAssesment, updateAssesmentInfo] = useUpdateAssesmentMutation();
  const [updateAssessmentCategories, updateAssessmentCategoriesInfo] = useUpdateAssesmentCategoryMutation();
  const { data: assesmentCategoryData, refetch: categoryRefetch } = useGetAssesmentCategoryQuery();

  useEffect(() => {
    categoryRefetch();
  },[])

  useEffect(() => {
    if (updateAssessmentCategoriesInfo.isSuccess) {
      showToast('success', 'Assessments Category successfully edited.');
      setEditModalOpen(false);
      categoryRefetch()
    }
    if (updateAssessmentCategoriesInfo.isError) {
      showToast('error', updateAssessmentCategoriesInfo.error.data.msg);
      updateAssessmentCategoriesInfo.reset();
    }
  }, [updateAssessmentCategoriesInfo])

  const { data: assesmentQuestionsData, refetch } = useGetAssesmentQuestionsQuery(assessmentEditId, {
    skip: assessmentEditId === undefined,
  });
  const [addAssesmentQuestions, addAssesmentQuestionsInfo] = useAddAssesmentQuestionsMutation();
  const [deleteAssesmentQuestions] = useDeleteAssesmentQuestionsMutation();
  // const [textAssesmentQuestions, textAssesmentQuestionsInfo] = useTextAssesmentQuestionsMutation();
  // const [selectAssesmentQuestions, selectAssesmentQuestionsInfo] = useSelectAssesmentQuestionsMutation();
  // const [checkAssesmentQuestions, checkAssesmentQuestionsInfo] = useCheckAssesmentQuestionsMutation();
  const [addAssesment, addAssesmentInfo] = useAddAssesmentMutation();
  const [currentSelectedType, setCurrentSelectedType] = useState('');
  const [assesmentName, setAssesmentName] = useState(
    assessmentEditId && assesmentQuestionsData ? assesmentQuestionsData.assesment.name : ''
  );
  const [selectedAssesmentCategory, setSelectedAssesmentCategory] = useState(
    assessmentEditId && assesmentQuestionsData ? assesmentQuestionsData.assesment.category : ''
  );
  const [assesmentId, setAssesmentId] = useState(assessmentEditId);

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState();

  const [questions, setQuestions] = useState([]);
  useEffect(() => {
    if (assesmentQuestionsData?.assesment?.form) {
      setQuestions([...assesmentQuestionsData?.assesment?.form])
    }
  }, [assesmentQuestionsData?.assesment?.form])
  const [modal2Open, setModal2Open] = useState(false);
  const [modal2Name, setModal2Name] = useState('add');
  // const [addAssessmentCategories, AddAssessmentCategoriesInfo] = useAddAssesmentCategoryMutation();
  // const isValidateCandidate = () => {

  //   if (newFormData.first_name === null || newFormData.first_name === '' || newFormData.first_name === undefined) {
  //     status = false;
  //     showToast('error', 'fill First Name');
  //   }}

  const { data: companyInfo } = useGetCompanyInfoQuery()
  //  const addClickHandler = async () => {
  //     setBtnLoader(true);
  //     if (modal2Name === 'Add') {
  //       await addAssessmentCategories(addValue);
  //       modalHandleClose(false);
  //     } else {
  //       console.log(modal2Name);  
  //     }
  //   };

  const handlePreviewClick = () => {
    setShowComponent(!showComponent);
  };
  const handleAssesmentClick = () => {
    setShowComponent(!showComponent);
  }
  const handleCheckboxChange = (e, questionIndex) => {
    setChecked(e.target.checked)
    setQuestions(pre => {
      const clonedArray = JSON.parse(JSON.stringify(pre));
      const inputValue = e.target.checked;
      clonedArray[questionIndex].optional = inputValue
      return clonedArray;
    });
  };

  const onEditModalHandler = (item) => {
    setEditModalOpen(true);
    setEditValue(item);
  };

  const modalHandleClose = () => {
    setEditModalOpen(false);
  };

  // Edit Handler
  const editChangeHandler = (e) => {
    setEditValue({ ...editValue, [e.target.name]: e.target.value });
  };

  const addClickHandler = async () => {
    // setBtnLoader(true);
    await updateAssessmentCategories(editValue);
  };

  const onAssesmentNameInputChangeHandler = (e) => {
    e.preventDefault();
    setAssesmentName(e.target.value);
  };
  const onAssesmentCategoryChangeHandler = (e) => {
    e.preventDefault();
    setSelectedAssesmentCategory(e.target.value);
  };
  const onAssesmentQuestionNameInputChangeHandler = (e, questionIndex) => {
    e.preventDefault();
    // questions[questionIndex].question = e.target.value;
    // setQuestions([...questions]);

    setQuestions(pre => {
      const clonedArray = JSON.parse(JSON.stringify(pre));
      clonedArray[questionIndex].question = e.target.value;
      return clonedArray;
    });
  };
  const onAssesmentOptionInputChangeHandler = (e, optIndex, questionIndex) => {
    e.preventDefault();
    // questions[questionIndex].options[optIndex] = e.target.value;
    // setQuestions([...questions]);
    setQuestions(pre => {
      const clonedArray = JSON.parse(JSON.stringify(pre));
      clonedArray[questionIndex].options[optIndex] = e.target.value;
      return clonedArray;
    });

  };
  // const onAssesmentOptionQualifyMarksInputChangeHandler = (e, optIndex, questionIndex) => {
  //   e.preventDefault();
  //   const inputValue = parseInt(e.target.value, 10);

  //   if (!Number.isNaN(inputValue)) {
  //     const maxMarks = questions[questionIndex].marks;

  //     if (inputValue > maxMarks) {
  //       console.log("Value entered is greater than the allowed marks.");
  //     } else {
  //       setQuestions((pre) => {
  //         const clonedArray = JSON.parse(JSON.stringify(pre));
  //         const newValue = Math.max(0, inputValue);
  //         clonedArray[questionIndex].answers[optIndex] = parseInt(newValue, 10);
  //         return clonedArray;
  //       });
  //     }
  //   } else {
  //     console.error('Invalid input for marks:', e.target.value);
  //   }
  // };

  const onAssesmentOptionQualifyMarksInputChangeHandler = (e, optIndex, questionIndex) => {
    e.preventDefault();
    const inputValue = e.target.value.trim(); // Trim any leading/trailing spaces
  
    if (inputValue === "" || /^[0-9]+$/.test(inputValue)) {
      const parsedValue = inputValue === "" ? "" : parseInt(inputValue, 10);
      const maxMarks = questions[questionIndex].marks;
  
      if (parsedValue > maxMarks) {
        showToast('error', 'Value entered is greater than the allowed marks.');

      } else {
        setQuestions((pre) => {
          const clonedArray = JSON.parse(JSON.stringify(pre));
          const newValue = inputValue === "" ? "" : Math.max(0, parsedValue);
          clonedArray[questionIndex].answers[optIndex] = newValue;
          return clonedArray;
        });
      }
    } else {
      // Handle invalid input (optional)
      console.error('Invalid input for marks:', inputValue);
    }
  };

  
  const onAssesmentMarksInputChangeHandler = (e, questionIndex) => {
    setQuestions((pre) => {
      const clonedArray = JSON.parse(JSON.stringify(pre));
      const inputValue = e.target.value;

      // Check if inputValue is a valid number using Number.isNaN
      const marksValue = parseInt(inputValue, 10);
      const minimumMarks = 1;

      if (!Number.isNaN(marksValue) && marksValue >= minimumMarks) {
        clonedArray[questionIndex].marks = marksValue;
      } else {
        console.error('Invalid input for marks:', inputValue);
        showToast('error', `Marks must be at least ${minimumMarks}`);
      }
      return clonedArray;
    });

  };
  const onAssesmentAnswerInputChangeHandler = (e, questionIndex) => {
    e.preventDefault();
    questions[questionIndex].answer = parseInt(e.target.value, 10);
    setQuestions([...questions]);
  };

  const onQuestionDoneClicked = async (questionIndex) => {
    if (assesmentId) {
      if (questions[questionIndex].assesment) {
        await addAssesmentQuestions(questions[questionIndex]);
      } else {
        questions[questionIndex].assesment = assesmentId;
        await addAssesmentQuestions(questions[questionIndex]);
      }
    } else {
      setCurrentQuestionIndex(questionIndex);
      onAssesmentSaveClick();
    }
  };
  useEffect(() => {
    if (assesmentId && currentQuestionIndex) {
      questions[currentQuestionIndex].assesment = assesmentId;
      onQuestionDoneClicked(currentQuestionIndex);
    }
  }, [assesmentId]);
  const addOptionsSelection = (questionIndex, optIndex) => {
    // questions[questionIndex].options = [...questions[questionIndex].options, `Option ${optIndex + 2}`];
    // setQuestions([...questions]);
    setQuestions(pre => {
      const clonedArray = JSON.parse(JSON.stringify(pre));
      clonedArray[questionIndex].options = [...questions[questionIndex].options, ""];
      clonedArray[questionIndex].answers = [...questions[questionIndex].answers, 0];
      return clonedArray;
    });
  };

  const onCloseQuestionDeleteHandler = async (questionIndex) => {
    if (questions[questionIndex].id) {
      await deleteAssesmentQuestions(questions[questionIndex].id);
    }
    const newSelected = questions.filter((item) => item !== questions[questionIndex]);
    setQuestions(newSelected);
  };

  const onDeleteOptionHandler = (questionIndex, optIndex) => {
    setQuestions(pre => {
      const clonedArray = JSON.parse(JSON.stringify(pre));

      if(clonedArray[questionIndex].options.length > 1){
        clonedArray[questionIndex].options.splice(optIndex, 1);
        clonedArray[questionIndex].answers.splice(optIndex, 1);
      } else {
        showToast('error', 'At least one option must remain.');
      }
      return clonedArray;
    });
  };

  const addNewCategoryHandler = () => {
    setModal2Open(true);
    setModal2Name('Add');
  };
  const onSelectedQuestionTypeClicked = (type) => {
    setCurrentSelectedType(type);
    handleCloseModal();
    switch (type) {
      case 'S':
        // setQuestions([
        //   ...questions,
        //   {
        // assesment: assesmentId,
        // type: 'S',
        // question: '',
        // options: ['Option 1', 'Option 2'],
        // marks: null,
        // answer: null,
        //   },
        // ]);
        setQuestions((prev) => [...prev, {
          assesment: assesmentId,
          type: 'S',
          question: '',
          options: ['', ''],
          marks: null,
          answers: [],
          answer: null,
          optional: false
        }])
        break;
      case 'T':
        setQuestions((prev) => [...prev,
        {
          assesment: assesmentId,
          type: 'T',
          question: '',
          marks: null,
          answers: [],
          answer: null,
          optional: false
        }
        ])
        break;
      case 'C':
        // setQuestions([
        //   ...questions,
        //   {
        // assesment: assesmentId,
        // type: 'C',
        // question: '',
        // options: ['Option 1', 'Option 2'],
        // marks: null,
        //   },
        // ]);
        setQuestions((prev) => [...prev, {
          assesment: assesmentId,
          type: 'C',
          question: '',
          options: ['', ''],
          marks: null,
          answers: [0, 0],
          answer: null,
          optional: false
        }])

        break;
      case 'R':
        // setQuestions([
        //   ...questions,
        //   {
        // assesment: assesmentId,
        // type: 'R',
        // question: '',
        // options: ['Option 1', 'Option 2'],
        // marks: null,
        // answer: null,
        //   },
        // ]);
        setQuestions((prev) => [...prev, {
          assesment: assesmentId,
          type: 'R',
          question: '',
          options: ['', ''],
          marks: null,
          answers: [0, 0],
          answer: null,
          optional: false
        }])
        break;
      default:
        break;
    }
    setCurrentSelectedType('');
  };

  const isValidateSaveAssesment = () => {
    let status = true;
    const hasEmptyQuestion = questions.map(questionObject => questionObject.question === "").includes(true);
    const qualifingMarks = questions.map(questionObject => questionObject.marks === "" || questionObject.marks === 0 || questionObject.marks === null).includes(true);
    const hasEmptyOptions = questions.map(questionObject => questionObject.options?.some(option => option === ""));
    const hasErrors = questions.some(questionObject => {
      const { options, answers } = questionObject;

      if (answers?.length < options?.length) {
        return true;
      }

      if (answers.some(answer => answer === "" || Number.isNaN(answer))) {
        return true;
      }

      return false;
    });
    if (selectedAssesmentCategory === undefined || selectedAssesmentCategory === '') {
      status = false;
      showToast('error', 'Select Catgegory');
    } else if (assesmentName === undefined || assesmentName === '') {
      status = false;
      showToast('error', 'Enter Assestment Name');
    } else if (questions.length === 0) { // New validation for empty questions array
      status = false;
      showToast('error', 'Please add at least one question!');
    }else if (hasEmptyQuestion) {
      status = false;
      showToast('error', "Please fill out all questions!");
    }
    else if (qualifingMarks) {
      status = false;
      showToast('error', "Please fill out all qualifying marks!");
    }
    else if (hasEmptyOptions.includes(true)) {
      status = false;
      showToast('error', "Please fill out all options");
    }
    else if (hasErrors) {
      status = false;
      showToast('error', "options marks empty or less than 1");
    }
    // if(assesmentQuestionsData===null||assesmentQuestionsData===''||assesmentQuestionsData===undefined){
    //   console.log("question test")
    //   status = false;
    //   showToast('error', 'Enter Questions');
    // }
    return status;
  };

  const onAssesmentSaveClick = async () => {
    
    if (isValidateSaveAssesment()) {
      if (assessmentEditId) {
        const data = {
          category: selectedAssesmentCategory,
          name: assesmentName,
          company: companyInfo?.companies?.id,
          form: questions
        }
        await updateAssesment({ data, id: assessmentEditId });
      }
      else {
        await addAssesment({
          category: selectedAssesmentCategory,
          name: assesmentName,
          company: companyInfo?.companies?.id,
          form: questions
        });
      }
    }
  };
  useEffect(() => {
    if (assesmentQuestionsData) {
      // setQuestions(assesmentQuestionsData.questions);
      setSelectedAssesmentCategory(assesmentQuestionsData.assesment.category);
      setAssesmentName(assesmentQuestionsData.assesment.name);
    }
  }, [assesmentQuestionsData]);
  useEffect(() => {
    if (assessmentEditId) {
      refetch();
    }
  }, [assessmentEditId, refetch]);
  useEffect(() => {
    if (addAssesmentInfo.isSuccess) {
      showToast('success', 'Assesment Saved Sucessfully');
      const savedAssesmentRecord = addAssesmentInfo.data.data.find((item) => item.name === assesmentName);
      setAssesmentId(savedAssesmentRecord.id);
      addAssesmentInfo.reset();
      if (isModal) {
        onClose(); // Close the modal
      } else {
        navigate(-1); // Navigate back if not in a modal
      }
    }
    if (addAssesmentInfo.isError) {
      showToast('error', addAssesmentInfo.error.data.msg);
      addAssesmentInfo.reset();
    }
    if (addAssesmentQuestionsInfo.isSuccess) {
      showToast('success', 'Assesment Question Saved Sucessfully');
      setQuestions(addAssesmentQuestionsInfo.data.questions);
      addAssesmentQuestionsInfo.reset();
      navigate("/dashboard/assessments")
    }
    if (addAssesmentQuestionsInfo.isError) {
      showToast('error', addAssesmentQuestionsInfo.error.data.msg);
      addAssesmentQuestionsInfo.reset();
    }
    if (updateAssesmentInfo.isSuccess) {
      showToast('success', 'Assesment update Sucessfully');
      navigate("/dashboard/assessments")
    }
  }, [addAssesmentInfo, addAssesmentQuestionsInfo, assesmentName, updateAssesmentInfo, navigate, isModal, onClose]);

  const handleScroll = (e) => {
    // Set the specific position to hide content
    const positionToHideContent = 200; // Adjust as needed
    setScrollPosition(e.target.scrollTop);
  };

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  function AssesmentPreview(props) {
    const { assesmentName, questions } = props;
    const [checked, setChecked] = useState(false);
    const navigate = useNavigate()
    
    return (
      <div className='preview-container' style={{ padding: '0px 60px' }}>
        <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '20px' }}>
          <Box onClick={handleAssesmentClick} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', padding: '10px', '&:hover': { cursor: 'pointer', backgroundColor: '#D9D9D9', borderRadius: '10px', transition: 'background-color 0.3s ease' } }}>
            <img style={{ marginBottom: '6px' }} src={vector} alt="" width='11px' height='19px' />
            <Stack sx={{ fontSize: '22px', fontWeight: '700', color: '#000', paddingLeft: '30px' }}>Preview</Stack>
          </Box>
          <Button variant="contained" onClick={onAssesmentSaveClick}>
            Save
          </Button>
        </Stack>
        <Box />
        <Stack sx={{ flexGrow: 1, background: 'white', borderRadius: '15px 15px 0px 0px' }} className='first-stack'>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack gutterBottom style={{ fontSize: '20px', fontWeight: '700', paddingTop: '10px', paddingLeft: '30px' }}>
                {assesmentName}
              </Stack>
            </Grid>
          </Grid>
          {questions?.map((question, index) => (
            <Box key={index} sx={{ padding: '30px', margin: '20px', background: '#F9F9F9', borderRadius: '10px' }}>
              <Grid display={'flex'} flexDirection="row">
                <Stack gap={3}>
                  <Stack>
                    <p style={{ fontSize: '18px', fontWeight: 600 }}>{`Question ${index + 1}`}: {question.question}
                      {question.optional && (
                        <span style={{ fontSize: '14px', paddingLeft: '10px' }}>
                          Required *
                        </span>
                      )}
                    </p>
                  </Stack>
                  {{
                    R: (
                      <div>
                        {question.options?.map((opt, optIndex) => (
                          <Stack key={optIndex} sx={{ display: 'flex', flexDirection: 'row', paddingBottom: '20px' }}>
                            <input type="radio" name={question.question} style={{ width: '25px', height: '25px' }} />
                            <div style={{ paddingLeft: '15px', fontSize: '18px', fontWeight: 400, color: 'var(--Gray-2, #4F4F4F)' }}>{opt}</div>
                          </Stack>
                        ))}
                      </div>
                    ),
                    C: (
                      <div>
                        {question.options?.map((opt, optIndex) => (
                          <Stack key={optIndex} sx={{ display: 'flex', flexDirection: 'row', paddingBottom: '20px' }}>
                            <input type="checkbox" name="option" style={{ width: '25px', height: '25px' }} />
                            <div style={{ paddingLeft: '15px', fontSize: '18px', fontWeight: 400, color: 'var(--Gray-2, #4F4F4F)' }}>{opt}</div>
                          </Stack>
                        ))}
                      </div>
                    ),
                    T: <TextareaAutosize
                      style={{ padding: '10px' }}
                      minRows={5}
                      cols={40}
                      placeholder="Type your answer"
                    />,
                    S: (
                      <select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        style={{ width: '250px', height: '40px', marginLeft: '30px' }}
                      >
                        <option value="" selected>
                          Select one from below
                        </option>
                        {question.options?.map((opt, optIndex) => (
                          <option key={optIndex}>{opt}</option>
                        ))}
                      </select>
                    ),
                  }[question.type]}
                </Stack>
              </Grid>
            </Box>
          ))}
        </Stack>
      </div>
    );
  }


  return (
    <>
      {showComponent ? <AssesmentPreview assesmentName={assesmentName} questions={questions} /> :
        <Container sx={{ mt: 2 }}>
          <Grid container spacing={2}  sx={{ width: '100%' }}>
              <Grid item xs={12} md={8} display="flex" alignItems="center" spacing={2}>
              {!isModal && (
                <Grid item>
                  <Button onClick={() => navigate(-1)}>
                    <img src={Back} alt="Go Back" />
                  </Button>
                </Grid>
              )}
                <Grid item sx={{ ml: 2 }}>
                  <Typography 
                      variant="h4" 
                      color="textPrimary" 
                      sx={{ 
                        width: { xs: '100%', md: '300px' },
                        textDecorationLine: 'underline',
                        backgroundColor: 'transparent !important'
                      }}
                    >
                      {assessmentEditId ? 'Edit' : 'Create'} an Assessment
                    </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} md={4} display="flex" justifyContent={{ xs: 'center', md: 'flex-end' }}>
                <Grid style={{ marginRight: 5 }}>
                  <Button variant="contained" onClick={onAssesmentSaveClick}>
                    Save
                  </Button>
                </Grid>
                <Grid style={{ marginRight: 5 }}>
                  <Button variant="contained" onClick={handlePreviewClick}>
                    Preview
                  </Button>
                </Grid>
                {isModal && (
                  <Grid style={{ marginRight: 5 }}>
                    <Button variant="contained" onClick={onClose}>
                      Close
                    </Button>
                  </Grid>
                )}
              </Grid>
             
              <Grid item xs={12}>
                <Card variant="outlined" style={{ padding: 20, borderRadius: '16px 16px 0px 0px' }}>
                  <Grid item xs={12} sx={{
                    marginBottom: '15px',
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: 'end'
                  }}>
                    <FormControl sx={{ width: isMobile ? "100%" : "60%"}}>
                      <InputLabel
                        id="demo-simple-select-standard-label"
                        shrink={false}
                        style={{ display: selectedAssesmentCategory ? 'none' : 'block' }}
                        sx={{ height: "60%"}}
                      >
                        Select Assessment Category
                      </InputLabel>
                      <Select
                        sx={{ borderRadius: '8px' }}
                        required
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={selectedAssesmentCategory}
                        onChange={onAssesmentCategoryChangeHandler}
                        onOpen={() => setIsSelectOpen(true)}
                        onClose={() => setIsSelectOpen(false)}
                        inputProps={{ 'aria-label': 'Without label' }}
                        MenuProps={{
                          PaperProps: {
                              style: {
                                  maxHeight: 48 * 4 + 8,
                                  width: 250,
                              },
                          },
                        }}

                      >
                        {assesmentCategoryData &&
                          assesmentCategoryData?.data?.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              <Grid container sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <Grid item sx={10}>{item?.name}</Grid>
                                {isSelectOpen && (
                                  <Grid item sx={2}>
                                    <Button style={{ minWidth: 0 }}>
                                      <ListItemIcon style={{ color: '#fff', padding: '0px', minWidth: 0 }}>
                                        <Iconify icon="ep:edit" width={24} height={24} color={'blue'} onClick={() => onEditModalHandler(item)} />
                                      </ListItemIcon>
                                    </Button>
                                  </Grid>
                                )}
                              </Grid>
                            </MenuItem>
                          ))}
                          {assesmentCategoryData?.data?.length === 0 && (
                            <MenuItem value={''}>Assessment Category Not Listed</MenuItem>
                          )}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} display="flex">
                    <Grid item xs={12} style={{ justifyContent: 'right' }}>
                      <Stack sx={{ fontSize: '18px', fontWeight: '600', marginBottom: '15px' }}>
                        Select Questions Types
                      </Stack>
                    </Grid>
                  </Grid>
                  <Grid container gap={2}  >
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="question-type-select-label">Question Type</InputLabel>
                        <Select
                          labelId="question-type-select-label"
                          id="question-type-select"
                          value={currentSelectedType}
                          onChange={(e) => onSelectedQuestionTypeClicked(e.target.value)}
                          label="Question Type"
                        >
                          <MenuItem value="" disabled>Select a question type</MenuItem>
                          <MenuItem value="R">Multiple Choices Questions</MenuItem>
                          <MenuItem value="C">Multiple CheckBoxes</MenuItem>
                          <MenuItem value="T">Text Paragraph</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <InputLabel sx={{ textAlign: 'start', color: '#000' }}>Give Name to your Assessment</InputLabel>
                      <TextField
                        sx={{ background: 'var(--white, #FFF)' }}
                        required
                        autoFocus
                        margin="dense"
                        fullWidth
                        name="aasesmentName"
                        value={assesmentName}
                        placeholder="Enter Assessment Name"
                        onChange={onAssesmentNameInputChangeHandler}
                      />
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            {/* </div> */}

            <Grid container className="" onScroll={handleScroll} sx={{ marginTop: '10px', marginLeft: '15px' }}>
              <Grid item xs={12} sx={{ display: "flex", gap: "1rem", flexDirection: "column" }}>
                {
                  (questions)?.map((item, index) =>
                    <Card variant="outlined" style={{ padding: 20, borderRadius: '0px', background: '#F9F9F9' }} key={index}>
                      <Grid item xs={12} style={{ marginBottom: 20 }}>
                        {item.type === 'T' ? (
                          <Grid key={`text-${index}`} item xs={12} style={{ margin: 15 }}>
                            <Grid display="flex" item xs={12}>
                              <Grid item xs={11} style={{ margin: 15 }}>
                                <Stack sx={{ fontSize: '18px', fontWeight: '600' }} >
                                  Question {index + 1}: Text Question
                                </Stack>
                              </Grid>
                              <Grid item xs={1} sx={{ textAlign: 'end' }}>
                                <Button style={{ background: 'var(--Red, #EB5757)', color: 'white', borderRadius: '6px', width: '10px', height: '40px' }} onClick={() => onCloseQuestionDeleteHandler(index)}>
                                  <CloseIcon />
                                </Button>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} gap={2} sx={{ display: 'flex', flexDirection: 'row', flexWrap: isMobile ? 'wrap' : 'nowrap' }}>
                              <Stack sx={{ width: '90%', paddingTop: '10px' }}>
                                <InputLabel sx={{ textAlign: 'start', color: '#000' }}>
                                  Question
                                  <span style={{ fontSize: '14px', paddingLeft: '10px' }}>
                                    <Checkbox
                                      checked={item.optional}
                                      onChange={(e) => handleCheckboxChange(e, index)}
                                      color="primary"
                                    />
                                    Required
                                  </span>
                                </InputLabel>
                                <TextField
                                  required
                                  autoFocus
                                  margin="dense"
                                  placeholder="Enter Your Question"
                                  fullWidth
                                  name="question"
                                  value={item.question}
                                  onChange={(e) => onAssesmentQuestionNameInputChangeHandler(e, index)}
                                />
                              </Stack>
                              <Stack sx={{ width: isMobile ? '80%' : '10%', paddingTop: '10px', justifyContent: 'end' }}>
                                <InputLabel sx={{ textAlign: 'start', color: '#000' }}>Qualifying Marks</InputLabel>
                                <TextField
                                  required="true"
                                  autoFocus
                                  margin="dense"
                                  placeholder="Enter Qualifying Marks"
                                  fullWidth
                                  name="Marks"
                                  value={item.marks}
                                  onChange={(e) => onAssesmentMarksInputChangeHandler(e, index)}
                                  type="number"
                                  defaultValue="0"
                                />
                              </Stack>
                            </Grid>
                          </Grid>
                        ) : (
                          <div>
                            {(item.type === 'C') ||
                              (item.type === 'R') ? (
                              <div key={`multiple-${index}`}>
                                <Grid item xs={12} style={{ margin: 15 }}>
                                  <Grid display="flex" item xs={12} marginBottom={2}>
                                    <Grid item xs={11}>
                                      <Stack sx={{ fontSize: '18px', fontWeight: '600' }}>
                                        Question {index + 1} : {item.type === "R" ? "Multiple Choice" : "Multi Select"} Question
                                      </Stack>
                                    </Grid>
                                    <Grid item xs={1} sx={{ textAlign: 'end', paddingRight: isMobile ? '20px' : '0' }}>
                                      <Button style={{ background: 'var(--Red, #EB5757)', color: 'white', borderRadius: '6px', width: '10px', height: '40px', minWidth: isMobile ? '50px' : '64px' }} onClick={() => onCloseQuestionDeleteHandler(index)}>
                                        <CloseIcon />
                                      </Button>
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={12} gap={2} sx={{ display: 'flex', flexDirection: 'row', flexWrap: isMobile ? 'wrap' : 'nowrap' }}>
                                    <Stack sx={{ width: '90%', paddingTop: '10px' }}>
                                      <InputLabel sx={{ textAlign: 'start', color: '#000' }}>
                                        Question
                                        <span style={{ fontSize: '14px', paddingLeft: '10px' }}>
                                          <Checkbox
                                            checked={item.optional}
                                            onChange={(e) => handleCheckboxChange(e, index)}
                                            color="primary"
                                          />
                                          Required
                                        </span>
                                      </InputLabel>
                                      <TextField
                                        sx={{ background: 'var(--white, #FFF)' }}
                                        required
                                        autoFocus
                                        margin="dense"
                                        name="question"
                                        value={item.question}
                                        placeholder="Enter Your Question"
                                        onChange={(e) => onAssesmentQuestionNameInputChangeHandler(e, index)}
                                      />
                                    </Stack>
                                    {(item.type === 'C' || item.type === 'R') && (
                                      <Stack sx={{ width: isMobile ? '80%' : '10%', paddingTop: '10px', justifyContent: 'end' }}>
                                        <InputLabel sx={{ textAlign: 'start', color: '#000' }}>Qualifying Marks
                                          <Tooltip title="Unqualified, If the score is Equal to or less than given score" placement="right">
                                            <IconButton>
                                              <div className="info-icon">i</div>
                                            </IconButton>
                                          </Tooltip>
                                        </InputLabel>
                                        <TextField
                                          sx={{ background: 'var(--white, #FFF)' }}
                                          required
                                          autoFocus
                                          margin="dense"
                                          placeholder="Enter Qualifying Marks"
                                          name="marks"
                                          value={item.marks}
                                          onChange={(e) => onAssesmentMarksInputChangeHandler(e, index)}
                                          type="number"
                                          defaultValue="0"
                                        />
                                      </Stack>
                                    )}
                                  </Grid>

                                  {item.options.map((opt, optIndex) => (
                                    <Grid container key={`options-${optIndex}`} display="flex" alignItems="end">
                                      <Grid item xs={12} gap={2} sx={{ display: 'flex', flexDirection: 'row', flexWrap: isMobile ? 'wrap' : 'nowrap' }}>
                                        <Stack sx={{ width: '90%', paddingTop: isMobile ? '0px' : '10px' }}>
                                          <InputLabel sx={{ textAlign: 'start', color: '#000' }}>{`Option ${optIndex + 1}`}</InputLabel>
                                          <TextField
                                            sx={{ background: 'var(--white, #FFF)' }}
                                            required
                                            autoFocus
                                            margin="dense"
                                            fullwidth
                                            placeholder={`Enter Option ${optIndex + 1}`}
                                            name={opt}
                                            value={opt}
                                            onChange={(e) => onAssesmentOptionInputChangeHandler(e, optIndex, index)}

                                          />
                                        </Stack>
                                        <Stack sx={{ width: isMobile ? '40%' : '10%', paddingTop: isMobile ? '0px' : '40px' }}>
                                          <TextField
                                            sx={{ background: 'var(--white, #FFF)' }}
                                            type="number"
                                            value={item.answers[optIndex]}
                                            onChange={e => onAssesmentOptionQualifyMarksInputChangeHandler(e, optIndex, index)}
                                            required
                                            placeholder='Marks'
                                            defaultValue={0}
                                          />
                                        </Stack>
                                        <Stack sx={{ width: '10%', paddingTop: isMobile ? '10px' : '50px' }}>
                                          <Button
                                            style={{ background: 'var(--Red, #EB5757)', color: 'white', borderRadius: '6px', width: '30px', height: '32px' }}
                                            onClick={() => onDeleteOptionHandler(index, optIndex)}
                                          >
                                            <CloseIcon />
                                          </Button>
                                        </Stack>
                                      </Grid>
                                      <Grid item xs={12}>
                                        {item.options.length === optIndex + 1 && (
                                          <Grid item xs={1} sx={{marginTop: isMobile ? '10px' : '0px' }}>
                                            <Button sx={{ background: 'var(--Blue, #2065D1)', color: 'white', borderRadius: '6px', width: '30px', height: '32px' }} onClick={() => addOptionsSelection(index, optIndex)}>&#10010;</Button>
                                          </Grid>
                                        )}
                                      </Grid>
                                    </Grid>
                                  ))}
                                </Grid>
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        )}
                      </Grid>
                    </Card>
                  )}
              </Grid>
            </Grid>
          </Grid>

          <Button
            style={{ 
              position: isModal ? 'sticky' : 'fixed', 
              bottom: isModal ? '0px' : '25px', 
              left: '50%', 
              transform: 'translateX(-50%)', 
              backgroundColor: 'blue', 
              color: 'white', 
              width: '150px', 
              height: '50px', 
              display: 'flex', 
              flexDirection: 'row', 
              alignItems: 'center', 
              justifyContent: 'center',
            }}
            onClick={handleOpenModal}
          >
            <AddIcon style={{ marginRight: '5px', color: 'white' }} />
            <Typography variant="caption" style={{ color: 'white', fontWeight: 'bold', backgroundColor: 'transparent' }}>
              Add Question
            </Typography>
          </Button>

          <Modal
            open={modalOpen}
            onClose={handleCloseModal}
            aria-labelledby="select-question-type-modal"
            aria-describedby="select-question-type-modal-description"
          >
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '300px', backgroundColor: 'white', padding: '20px', boxShadow: '0 2px 5px rgba(0,0,0,0.3)' }}>
              <Grid container gap={2} justifyContent="center">
                <Grid item xs={12} display="flex" justifyContent="center">
                  <Stack sx={{ fontSize: '18px', fontWeight: '600', marginBottom: '15px' }}>
                    Select Questions Types
                  </Stack>
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormControl fullWidth>
                    <InputLabel id="question-type-select-label">Question Type</InputLabel>
                    <Select
                      labelId="question-type-select-label"
                      id="question-type-select"
                      value={currentSelectedType}
                      onChange={(e) => onSelectedQuestionTypeClicked(e.target.value)}
                      label="Question Type"
                    >
                      <MenuItem value="" disabled>Select a question type</MenuItem>
                      <MenuItem value="R">Multiple Choices Questions</MenuItem>
                      <MenuItem value="C">Multiple CheckBoxes</MenuItem>
                      <MenuItem value="T">Text Paragraph</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="flex-end">
                  <Button onClick={handleCloseModal} color="primary">
                    Close
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Modal>

          <CandidateSettingModal
            open={editmodalOpen}
            handleClose={modalHandleClose}
            label="Edit Category Name"
            type="Edit"
            textboxlabel="Edit Category"
            id="editCategoryName"
            value={editValue.name}
            name="Edit"
            onChange={editChangeHandler}
            addClickHandler={addClickHandler}
            loadingbtn={btnLoader}
          />
        </Container>
      }
    </>
  );
};

export default CreateAssessment;
