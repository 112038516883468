import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
// mock
// import account from '../../_mock/account';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
//
import { newNavBar, mainSideBarData, settingsSideBarData } from './NavConfig';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.white,
  top:`calc(50%-0vh)`,
  marginTop: "80px",
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
    top:`calc(50-vh%)`,
    marginTop: "80px",
  },
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.white,
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const isDesktop = useResponsive('up', 'lg');
  const [isSidebarOpen, setSidebarOpen] = useState(isOpenSidebar);

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    setSidebarOpen(false);
  }, [pathname]);

  const handleToggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const renderContent = (
    <Scrollbar
      sx={{
        height: '100%',
        marginTop:"10%",
        backgroundColor:"#ffffff",
        '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        {/* <Logo /> */}
      </Box>
      {isDesktop ? (
        <NavSection navConfig={newNavBar} />
      ):(
        <NavSection navConfig={newNavBar} mainConfig={mainSideBarData} />
      )}
      <Box sx={{ flexGrow: 1 }} /> {/* This ensures the bottom menu is pushed to the bottom */}
      <Box sx={{ px: 2.5, py: 3 }}>
        {/* Bottom Menu Content */}
      </Box>
    </Scrollbar>
  );

  return (
    <RootStyle sx={{backgroundColor:"#ffffff"}}>
      {!isDesktop && (
        <>
         
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleToggleSidebar}
            sx={{
              position: 'fixed',
              top: 23,
              left: 16,
              zIndex: 1300,
              minWidth: 'auto',
              padding: 1,
              color: 'primary.main',
              marginTop: "5px",
              
            }}
          >
            {isSidebarOpen ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
          <Drawer
            anchor="left"
            open={isSidebarOpen}
            onClose={handleToggleSidebar}
          >
            {renderContent}
          </Drawer>
          <Drawer
            open={isSidebarOpen}
            onClose={handleToggleSidebar}
            PaperProps={{
              sx: { width: DRAWER_WIDTH },
            }}
          >
            {renderContent}
          </Drawer>
        </>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
