import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select as AntSelect } from 'antd';
import dayjs from 'dayjs';
import { Autocomplete, CircularProgress, useMediaQuery } from '@mui/material';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import currencyCodes from 'currency-codes';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { DesktopDatePicker, LocalizationProvider } from '@mui/lab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useGetLocationQuery } from '../../../../redux/services/settings/LocationService';
import { jobAction, setJobAddressData, setJobOwner } from '../../../../redux/job/JobReducer';
import { useDegreeGetQuery } from '../../../../redux/services/settings/DegreeService';
import { useGetUsersApiQuery } from '../../../../redux/services/settings/UserService';
import { useDepartmentGetQuery } from '../../../../redux/services/settings/DepartmentService';
import { useGetStateQuery, useGetCityQuery } from '../../../../redux/services/settings/CountryStateCityService';
import { useGetPipelineQuery, useGetPipelineApiQuery } from '../../../../redux/services/settings/PipelineService';
import { useDesignationGetQuery } from '../../../../redux/services/settings/DesignationService';
import { useGetSkillListQuery } from '../../../../redux/services/candidate/SkillServices';

const FillDetails = () => {
  const dispatch = useDispatch();
  const quillRef = useRef(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const textValue = useSelector((state) => state.job.job);
  const { data: jobGetuserData, refetch: refetchUsers} = useGetUsersApiQuery();
  const { data: jobGetDepartmentData, refetch: refetchDepartments } = useDepartmentGetQuery();
  const { data: jobAddressData, refetch: refetchLocations } = useGetLocationQuery();
  const { data: jobGetPipelineApiData, refetch: refetchPipelines } = useGetPipelineApiQuery();
  const { data: jobDegreeData } = useDegreeGetQuery();
  const { data: jobGetPipelineData } = useGetPipelineQuery();
  const { data: jobGetDesignationData } = useDesignationGetQuery();
  const { data: skillsData, refetch: getSkillDataRefetch } = useGetSkillListQuery()

  useEffect(() => {
    refetchUsers();
    refetchDepartments();
    refetchLocations();
    refetchPipelines();
    getSkillDataRefetch();
  }, [refetchUsers, refetchDepartments, refetchLocations, refetchPipelines, getSkillDataRefetch]);
  
  useEffect(() => {
    if (jobAddressData) {
      dispatch(setJobAddressData(jobAddressData)); // Dispatch the full address data
    }
  }, [jobAddressData,dispatch]);

  useEffect(() => {
    if (jobGetuserData) {
      dispatch(setJobOwner(jobGetuserData)); // Dispatch the full address data
    }
  }, [jobGetuserData, dispatch]);
  
  const experienceArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
  //  const [textValue, setTextValue] = useState(job);
  const [teamMemberOptions, setTeamMemberOptions] = useState([]);
  // const [newdescription, setDescription] = useState('');
  const initialMemberIds = Array.isArray(textValue.member_names) ? textValue.member_names.map(member => member.account_id) : [];
  
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const modules = {
    toolbar: [
      [{ 'font': ['serif', 'sans-serif', 'monospace'] }], // Add more font options
      [{ 'size': ['small', false, 'large', 'huge'] }], // Add more size options if needed
      ['bold', 'italic', 'underline', 'strike'], // Add 'strike' for strikethrough
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}], // Add indent options
      [{ 'align': [] }], // Keep align options
      [{ 'color': [] }, { 'background': [] }], // Keep color and background options
      ['link'], // Add link, image, and video options
      ['clean'] // Keep clean option
    ]
  };
  const formats = [
      'font',
      'size',
      'bold', 'italic', 'underline', 'strike', // Add 'strike' for strikethrough
      'list', 'bullet', 'indent', // Add 'indent' for indentation
      'align',
      'color', 'background',
      'link'// Add 'link', 'image', 'video' for additional features
    ];

  const state = {
      comments: ''
  }

  const onInputChangeHandler = (name, value) => {
    const myObj = { ...textValue };
  
    if (myObj[name] === value) return;

    if (name === 'member_names') {
      const newValue = typeof value === 'string' ? value.split(',') : value;

      // Update member_names to store the entire member object
      myObj[name] = newValue.map(id => 
        jobGetuserData.list.find(member => member.account_id === id)
      ).filter(Boolean);

      // Update member_ids based on selected member objects
      myObj.member_ids = myObj[name].map(member => member.account_id);
    } else if (name === 'department') {
      // Ensure that the department is set correctly
      myObj[name] = value.id;;
      myObj.department_name = value.name;
    } else if (
      name === 'vacancies' ||
      name === 'assesment' ||
      name === 'exp_min' ||
      name === 'exp_max'
    ) {

      if(Number.isNaN(value) || value===""){
        myObj[name] = value;
      }
      else{
        const numericValue = parseInt(value, 10);
        myObj[name] = numericValue >= 0 ? numericValue : 0;
      }
    } else if (name === 'owner') {
      myObj[name] = value;
    } else if (name === 'description') {
      myObj[name] = value;
    } else {
      myObj[name] = value;
    }
    dispatch(jobAction({ ...myObj }));
  };

  useEffect(() => {
    if (textValue.description) {
      dispatch(jobAction({ description: textValue.description }));
    }
  }, [textValue.description, dispatch]);

//   const onDescriptionInputChangeHandler = (e) => {
//     setDescription(e)
//     dispatch(jobAction({ ...textValue , description: e}));
//   }

    const renderMultiSelectValues = (selected) => {
      // Ensure selected is an array before mapping
      if (!Array.isArray(selected)) {
        return '';
      }
      return selected.map(id => {
        const member = textValue.member_names.find(item => item.account_id === id);
        return member ? `${member.first_name} ${member.last_name}` : '';
      }).join(', ');
    };


  const handleKeyPress = (event) => {
    if (!/\d/.test(event.key) || event.key === '-') {
      event.preventDefault();
    }}

    const currencyList = currencyCodes.data.map(currency => ({
      code: currency.code,
      name: currency.currency
    }));

  if (!textValue) {
    return (
      <Container sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Card sx={{ p: isMobile ? 1 : 4, m: 2 ,height: "100%"}} variant="outlined">
      <Container >
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoFocus
                margin="dense"
                variant="standard"
                fullWidth
                name="title"
                value={textValue.title}
                label="Job Title"
                onChange={(e)=> onInputChangeHandler(e?.target?.name, e?.target?.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                variant="standard"
                type="number"
                fullWidth
                name="vacancies"
                value={textValue.vacancies || ''}
                label="Number of Vacancies"
                onChange={(e)=> onInputChangeHandler(e?.target?.name, e?.target?.value)}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                onKeyPress={handleKeyPress}
              />
            </Grid>

            {/* <Grid item xs={12} sm={6}>
              <FormControl variant="standard" sx={{ mt: 1, minWidth: '100%' }}>
                <InputLabel id="demo-simple-select-standard-label">Department</InputLabel>
                <Select
                  required
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={textValue?.department || ''}
                  onChange={(e) => {
                  const selectedDepartment = jobGetDepartmentData?.data?.find(item => item.id === e.target.value);
                  onInputChangeHandler(e.target.name, selectedDepartment); 
                  }}
                  
                  label="Select the Department"
                  name="department"
                >
                  {jobGetDepartmentData?.data?.map((item) => ( // Ensure you are accessing the correct property
                  <MenuItem key={item.id} value={item.id}>
                    {item?.name}
                  </MenuItem>
                ))}
                </Select>
              </FormControl>
            </Grid> */}

            <Grid item xs={12} sm={6}>
              <FormControl variant="standard" sx={{ mt: 1, minWidth: '100%' }}>
                <Autocomplete
                  id="department-autocomplete"
                  size="small"
                  options={jobGetDepartmentData?.data || []}
                  getOptionLabel={(option) => option.name || ''}
                  value={jobGetDepartmentData?.data?.find(item => item.id === textValue?.department) || null}
                  onChange={(event, newValue) => {
                    onInputChangeHandler('department', newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Department"
                      placeholder="Select the Department"
                    />
                  )}
                  ListboxProps={{
                    style: {
                      maxHeight: '200px', // Adjust this value to control the height
                      overflow: 'auto',
                    },
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl variant="standard" sx={{ mt: 1, minWidth: '100%' }}>
                <InputLabel id="demo-simple-select-standard-label">owner</InputLabel>
                <Select
                  margin="dense"
                  variant="standard"
                  fullWidth
                  name="owner"
                  value={textValue.owner?.account_id || ''}
                  label="owner"
          
                  onChange={(e) => {
                  const selectedOwner = jobGetuserData?.list?.find(item => item.account_id === e.target.value);
                  onInputChangeHandler(e.target.name, selectedOwner); 
                  }}
                  >
                  {jobGetuserData &&
                    jobGetuserData?.list?.map((item) => (
                      <MenuItem key={item.account_id} value={item.account_id}>
                        {item?.first_name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="standard" sx={{ mt: 1, minWidth: '100%' }}>
                <InputLabel id="demo-multiple-checkbox-label">Team Member</InputLabel>
                <Select
                  labelId="team-members"
                  id="team-members"
                  multiple
                  value={initialMemberIds}
                  name="member_names"
                  fullWidth
                  onChange={(e) => {
                    const selectedMembers = e.target.value; // Get selected members
                    onInputChangeHandler(e.target.name, selectedMembers); // Update state
                  }}
                  renderValue={renderMultiSelectValues}
                  MenuProps={MenuProps}
                  
                >
                  {jobGetuserData &&
                    jobGetuserData?.list?.map((item) => (
                      <MenuItem key={item.account_id} value={item.account_id}>
                        <ListItemText primary={`${item.first_name} ${item.last_name}`} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="standard" sx={{ mt: 1, minWidth: '100%' }}>
                <InputLabel id="demo-simple-select-standard-label">Type</InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={textValue.type}
                  onChange={(e)=> onInputChangeHandler(e?.target?.name, e?.target?.value)}
                  label="Type"
                  name="type"
                >
                  <MenuItem value={'FULL_TIME'}>Full Time</MenuItem>
                  <MenuItem value={'PART_TIME'}>Part Time</MenuItem>
                  {/* <MenuItem value={'C'}>Contract</MenuItem> */}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="standard" sx={{ mt: 1, minWidth: '100%' }}>
                <InputLabel id="demo-simple-select-standard-label">Job Nature</InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={textValue.nature}
                  onChange={(e)=> onInputChangeHandler(e?.target?.name, e?.target?.value)}
                  label="on site"
                  name="nature"
                >
                  <MenuItem value={'Physical'}>Physical</MenuItem>
                  <MenuItem value={'Remote'}>Remote</MenuItem>
                  {/* <MenuItem value={30}>Thirty</MenuItem> */}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="standard" sx={{ mt: 1, minWidth: '100%' }}>
                <InputLabel id="demo-simple-select-standard-label">Education</InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={textValue.education}
                  onChange={(e)=> onInputChangeHandler(e?.target?.name, e?.target?.value)}
                  label="Choose Degree"
                  name="education"
                >
                  <MenuItem value="HIGH_SCHOOL">High School</MenuItem>
                  <MenuItem value="JUNIOR_COLLEGE">Junior College</MenuItem>
                  <MenuItem value="BACHELORS">Bachelors</MenuItem>
                  <MenuItem value="MASTERS">Masters</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} style={{marginTop: "25px"}}>
                <AntSelect
                  mode="tags"
                  style={{ 
                    width: '100%',
                    marginTop: "8px",
                    borderLeft: 'white',
                    borderRadius: '0px',
                    borderRight: 'white',
                    borderTop: 'white',
                    borderBottomColor: '#c1c9d0',
                    '& .ant-select-selection-placeholder': {
                      color: '#555', // Change this to your desired light dark color
                    },
                    // borderBottomStyle: 'solid', // Ensure the bottom border is visible
                    // borderBottomWidth: '1px' // Set a width for the bottom border
                  }}
                  value={textValue?.speciality || []}
                  onChange={(value) => onInputChangeHandler('speciality', value)}
                  tokenSeparators={[',']}
                  options={skillsData?.data?.map(skill => ({
                    label: skill.name,
                    value: skill.name
                  })) || []}
                  placeholder="Select Speciality"
                />
              </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl variant="standard" sx={{ mt: 1, minWidth: '100%' }}>
                <TextField
                autoFocus
                margin="dense"
                variant="standard"
                type="number"
                  value={textValue.exp_min || ''}
                  onChange={(e)=> onInputChangeHandler(e?.target?.name, e?.target?.value)}
                  label="Work Ex. min. (years)"
                  name="exp_min"
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  onKeyPress={handleKeyPress}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl variant="standard" sx={{ mt: 1, minWidth: '100%' }}>
                <TextField
                autoFocus
                margin="dense"
                variant="standard"
                type="number"
                  value={textValue.exp_max || ''}
                  onChange={(e)=> onInputChangeHandler(e?.target?.name, e?.target?.value)}
                  label="Work Ex. max. (years)"
                  name="exp_max"
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  onKeyPress={handleKeyPress}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                margin="dense"
                variant="standard"
                fullWidth
                name="salary_min"
                value={textValue.salary_min}
                label="Salary Minimum"
                onChange={(e)=> onInputChangeHandler(e?.target?.name, e?.target?.value)}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                onKeyPress={handleKeyPress}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                margin="dense"
                variant="standard"
                fullWidth
                name="salary_max"
                value={textValue.salary_max}
                label="Salary Maximum"
                onChange={(e)=> onInputChangeHandler(e?.target?.name, e?.target?.value)}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                onKeyPress={handleKeyPress}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6} md={3}>
              <FormControl variant="standard" sx={{ mt: 1, minWidth: '100%' }}>
                <InputLabel id="demo-simple-select-standard-label">Currency</InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={textValue.currency}
                  onChange={(e)=> onInputChangeHandler(e?.target?.name, e?.target?.value)}
                  label="Currency"
                  name="currency"
                >
                  <MenuItem value={'INR'}>INR</MenuItem>
                  <MenuItem value={'US'}>US Dollar</MenuItem>
                </Select>
              </FormControl>
            </Grid> */}
            <Grid item xs={12} sm={6} md={3}>
              <FormControl variant="standard" sx={{ mt: 1, minWidth: '100%' }}>
                <Autocomplete
                  id="currency-autocomplete"
                  size="small"
                  options={currencyList}
                  getOptionLabel={(option) => `${option.name} (${option.code})`}
                  value={currencyList.find(item => item.code === textValue.currency) || null}
                  onChange={(event, newValue) => {
                    onInputChangeHandler('currency', newValue ? newValue.code : '');
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Currency"
                      placeholder="Select the Currency"
                    />
                  )}
                  ListboxProps={{
                    style: {
                      maxHeight: '200px', // Adjust this value to control the height
                      overflow: 'auto',
                    },
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl variant="standard" sx={{ mt: 1, minWidth: '100%' }}>
                <InputLabel id="demo-simple-select-standard-label">Salary Type</InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={textValue.salary_type}
                  onChange={(e)=> onInputChangeHandler(e?.target?.name, e?.target?.value)}
                  label="Salary Type"
                  name="salary_type"
                >
                  <MenuItem value={'YEARLY'}>Yearly</MenuItem>
                  <MenuItem value={'MONTHLY'}>Monthly</MenuItem>
                  <MenuItem value={'WEKLY'}>Weekly</MenuItem>
                  <MenuItem value={'DAILY'}>Daily</MenuItem>

                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="standard" sx={{ mt: 1, minWidth: '100%' }}>
                <InputLabel id="demo-simple-select-standard-label">Address</InputLabel>
                <Select
                  margin="dense"
                  variant="standard"
                  fullWidth
                  name="address"
                  value={textValue?.address || ''}
                  label="Address (name)"
                  onChange={(e)=> onInputChangeHandler(e?.target?.name, e?.target?.value)}
                >
                  {jobAddressData && jobAddressData?.data?.map((item)=>(
                    <MenuItem key={item.id} value={item.id}>
                    {item?.address}
                  </MenuItem>
                  ))}
                  {jobAddressData?.data?.length === 0 && (
                    <MenuItem value={''}>Address Not Listed</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="standard" sx={{ mt: 1, minWidth: '100%' }}>
                <InputLabel id="demo-simple-select-standard-label">Pipeline</InputLabel>
                <Select
                  margin="dense"
                  variant="standard"
                  fullWidth
                  name="pipeline"
                  value={textValue?.pipeline}
                  label="Pipeline"
                  onChange={(e)=> onInputChangeHandler(e?.target?.name, e?.target?.value)}
                >
                  {jobGetPipelineApiData && jobGetPipelineApiData.data.map((item)=>(
                    <MenuItem key={item.id} value={item.id}>
                    {item?.name}
                  </MenuItem>
                  ))}
                   {jobGetPipelineApiData?.data?.length === 0 && (
                    <MenuItem value={''}>Pipeline Not Listed</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
            {/* <Grid item xs={12} sm={6} sx={{ marginTop: 1 }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="Target Date"
                  // value={}
                  value={textValue.targetDate || ''}
                  onChange={(e) => {
                    const date = dayjs(e);
                    // const today = dayjs();
                    // setTargetDate(e);
                    onInputChangeHandler('targetDate', date.format('YYYY-MM-DD')); // Dispatch the change
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid> */}
            <Grid item xs={12}>
              <InputLabel id="demo-simple-select-standard-label" sx={{ mt: 2 }}>Job Description</InputLabel>
              <Box sx={{ minHeight: '400px', height: 'auto' }}>
             
                <ReactQuill
                  ref={quillRef}
                  theme="snow"
                  style={{
                    width: '100%',
                    minHeight: isMobile ? '14em' : '20em',
                    // overflowY: 'auto', 
                  }}
                  modules={modules}
                  formats={formats}
                  label="Job Description"
                  value={textValue.description || ''}
                  name="description"
                  onChange={(value) => onInputChangeHandler("description", value)}
                />
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div style={{ fontSize: '14px' }}>
                  Character Count: {textValue?.description?.length || 0}/9999
                </div>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Card>
  );
};

export default FillDetails;
