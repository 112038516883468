import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Modal,
  Box,
  Card,
  Grid,
  Button,
  Typography,
  Stack,
  Input,
  FormGroup,
  FormControlLabel,
  Switch,
  CardActions,
  CardContent,
  CardMedia,
  TextField,
  Container,
  Backdrop,
  useMediaQuery,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import linkedinnn from '../../../assets/images/job-boards/linkedinnn.jpg';
import naukri from '../../../assets/images/job-boards/naukriii.jpg';
import indeedlogo from '../../../assets/images/job-boards/indeeeddddddd.jpg';
import edjobster09 from "../../../assets/images/edjobster09.png";
import google from "../../../assets/images/google.png";
import { showToast } from '../../../utils/toast';
import { useJobBoardMutation } from '../../../redux/services/jobs/JobBoardServices';

const JobBoards = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm')); 
  const [openLinkedin, setOpenLinkedin] = useState(false);
  const [OpenNaukri, setOpenNaukri] = useState(false);
  const [Openindeeed, setOpenindeeed] = useState(false);
  const [OpenGoogle, setOpenGoogle] = useState(false);
  const [textValue, setTextValue] = useState({
    userIdLinkedin: '',
    privateKeyTokenLinkedin: '',
  });

  const [formData, setFormData] = useState({
    name: '',
    project_id: '',
    external: '',
    address: '',
    website: '',
    career_site: '',
    credentials: null,
  });

  const [addJobBoard, { isLoading, error }] = useJobBoardMutation();

  const handleChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    setFormData((prev) => ({ ...prev, credentials: e.target.files[0] }));
  };

  const handleSubmit = async () => {
    if (!formData.name || !formData.external || !formData.credentials) {
      showToast('error', 'Please fill in required fields and upload credentials.');
      return;
    }

    const formDataPayload = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      formDataPayload.append(key, value); 
    });
    formDataPayload.append('platform', 'Google');
    try {
      await addJobBoard(formDataPayload).unwrap();
      showToast('success', 'Google Job successfully added!');
      googleClose();
    } catch (err) {
      showToast('error', 'Error submitting Google Job: ');
    }
  };

  const onInputChangeHandler = (e) => {
    setTextValue(e.target.value);
    const myObj = {};
    myObj[e.target.name] = e.target.value;
  };

  const handleOpen = () => {
    setOpenLinkedin(true);
  };
  const handleClose = () => {
    setOpenLinkedin(false);
  };
  // naukri
  const naukriOpen = () => {
    setOpenNaukri(true);
  };
  const naukriClose = () => {
    setOpenNaukri(false);
  };
  // indeed
  const indeedOpen = () => {
    setOpenindeeed(true);
  };
  const indeedClose = () => {
    setOpenindeeed(false);
  };

  const googleOpen = () => {
    setOpenGoogle(true);
  };
  const googleClose = () => {
    setOpenGoogle(false);
  };

  const navigateCancel = () => {
    navigate("/dashboard/InstituteSettings/settings")
  }

  return (
    <Grid container spacing={2}>

      <Container
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <div className="backbutton tt-back" style={{ display: 'flex' }}>
              <ArrowBackIosIcon
                onClick={navigateCancel}
                sx={{
                  cursor: 'pointer',
                }}
              />
            </div>
            <Typography 
              variant="h3" 
              color="textPrimary" 
              sx={{ 
                width: '300px',
                textDecorationLine: 'underline',
                backgroundColor: 'transparent !important'
              }}
            >
              Job-Boards
            </Typography>
      </Container>

      <Grid item xs={12} style={{ display: 'flex', justifyContent: isMobile ? 'center' : 'flex-start' }}>
        <Grid item xs={ isMobile ? 10 : 4 }>
          <Card align="center">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card>
                  <br />
                  <Box>
                    <img src={google} alt="google" style={{ height: '150px', width: '200px' }} />
                  </Box>
                  <Box>
                    <FormGroup style={{ alignContent: 'center' }}>
                      <FormControlLabel
                        textAlign="center"
                        component={Button}
                        onClick={googleOpen}
                        control={<Switch onChange={handleChange} name="checkedA" />}
                        label=" "
                      />
                    </FormGroup>
                  </Box>
                </Card>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={ isMobile ? 10 : 4 }>
          <Card align="center">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card>
                  <br />
                  <Box>
                    <img src={edjobster09} alt="indeedlogo" style={{ height: '150px', width: '200px' }} />
                  </Box>
                  <Box>
                    <FormGroup style={{ alignContent: 'center' }}>
                      <FormControlLabel
                        textAlign="center"
                        component={Button}
                        // onClick={indeedOpen}
                        control={<Switch onChange={handleChange} name="checkedA" />}
                        label=" "
                      />
                    </FormGroup>
                  </Box>
                </Card>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>

      {/* comming soon */}

      <Grid item xs={12}>
        <Card align="center">
          <Typography variant="h3" color="textPrimary" sx={{ textDecorationLine: 'underline', backgroundColor: 'transparent !important' }}>
            Coming Soon ...
          </Typography>
        </Card>
      </Grid>
      <Grid item xs={12} style={{ display: 'flex', justifyContent: isMobile ? 'center' : 'flex-start', flexDirection: 'row', flexWrap: isMobile ? 'wrap' : 'nowrap' }}>
        <Grid item xs={isMobile ? 10 : 4} style={{ margin: 10 }}>
          <Card align="center">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card>
                  <br />
                  <Box>
                    <img src={linkedinnn} alt="linkedinnn" style={{ height: '150px', width: '200px' }} />
                  </Box>
                  <Box>
                    <FormGroup style={{ alignContent: 'center' }}>
                      <FormControlLabel
                        textAlign="center"
                        component={Button}
                        onClick={handleOpen}
                        control={<Switch onChange={handleChange} name="checkedA" />}
                        label=" "
                      />
                    </FormGroup>
                  </Box>
                </Card>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        {/* second boxxxxx */}
        <Grid item xs={isMobile ? 10 : 4} style={{ margin: 10 }}>
          <Card align="center">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card>
                  <br />
                  <Box>
                    <img src={naukri} alt="naukri" style={{ height: '150px', width: '200px' }} />
                  </Box>
                  <Box>
                    <FormGroup style={{ alignContent: 'center' }}>
                      <FormControlLabel
                        textAlign="center"
                        component={Button}
                        onClick={naukriOpen}
                        control={<Switch onChange={handleChange} name="checkedA" />}
                        label=" "
                      />
                    </FormGroup>
                  </Box>
                </Card>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        {/* third box */}

        <Grid item xs={isMobile ? 10 : 4} style={{ margin: 10 }}>
          <Card align="center">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card>
                  <br />
                  <Box>
                    <img src={indeedlogo} alt="indeedlogo" style={{ height: '150px', width: '200px' }} />
                  </Box>
                  <Box>
                    <FormGroup style={{ alignContent: 'center' }}>
                      <FormControlLabel
                        textAlign="center"
                        component={Button}
                        onClick={indeedOpen}
                        control={<Switch onChange={handleChange} name="checkedA" />}
                        label=" "
                      />
                    </FormGroup>
                  </Box>
                </Card>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
      {/* modal linkin */}
      <Modal
        open={openLinkedin}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(0, 0, 0, 0.5) !important' }}
        //  BackdropProps={{ style: { backgroundColor: 'transparent' } }}
        // BackdropProps={{ style: {  } }}
      >
        <Box sx={{ maxWidth: 600 }}>
          <Card p={2}>
            <CardMedia component="img" alt="linkedinnn" height="180" image={linkedinnn} />
            <CardContent>
              <Grid item xs={12}>
                <TextField
                  autoFocus
                  margin="dense"
                  variant="standard"
                  fullWidth
                  name="userIdLinkedin"
                  value={textValue.userIdLinkedin}
                  label="User ID"
                  onChange={onInputChangeHandler}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  autoFocus
                  margin="dense"
                  variant="standard"
                  fullWidth
                  name="privateKeyTokenLinkedin"
                  value={textValue.privateKeyTokenLinkedin}
                  label="Private key Bearer"
                  onChange={onInputChangeHandler}
                />
              </Grid>
            </CardContent>
            <CardActions>
              <Button variant="gradient" buttonColor="info" fullwidth>
                Validate
              </Button>
            </CardActions>
          </Card>
        </Box>
      </Modal>

      {/* modal naukri */}
      <Modal
        open={OpenNaukri}
        onClose={naukriClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box sx={{ maxWidth: 600 }}>
          <Card p={2}>
            <CardMedia component="img" alt="linkedinnn" height="180" image={naukri} />
            <CardContent>
              <Grid item xs={12}>
                <TextField
                  autoFocus
                  margin="dense"
                  variant="standard"
                  fullWidth
                  name="userIdLinkedin"
                  value={textValue.userIdLinkedin}
                  label="User ID"
                  onChange={onInputChangeHandler}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  autoFocus
                  margin="dense"
                  variant="standard"
                  fullWidth
                  name="privateKeyTokenLinkedin"
                  value={textValue.privateKeyTokenLinkedin}
                  label="Private key Bearer"
                  onChange={onInputChangeHandler}
                />
              </Grid>
            </CardContent>
            <CardActions>
              <Button variant="gradient" buttonColor="info" fullwidth>
                Validate
              </Button>
            </CardActions>
          </Card>
        </Box>
      </Modal>
      {/* modal indeed */}
      <Modal
        open={Openindeeed}
        onClose={indeedClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box sx={{ maxWidth: 600 }}>
          <Card p={2}>
            <CardMedia component="img" alt="linkedinnn" height="180" image={indeedlogo} />
            <CardContent>
              <Grid item xs={12}>
                <TextField
                  autoFocus
                  margin="dense"
                  variant="standard"
                  fullWidth
                  name="userIdLinkedin"
                  value={textValue.userIdLinkedin}
                  label="User ID"
                  onChange={onInputChangeHandler}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  autoFocus
                  margin="dense"
                  variant="standard"
                  fullWidth
                  name="privateKeyTokenLinkedin"
                  value={textValue.privateKeyTokenLinkedin}
                  label="Private key Bearer"
                  onChange={onInputChangeHandler}
                />
              </Grid>
            </CardContent>
            <CardActions>
              <Button variant="gradient" buttonColor="info" fullwidth>
                Validate
              </Button>
            </CardActions>
          </Card>
        </Box>
      </Modal>
      <Modal
        open={OpenGoogle}
        onClose={googleClose}
        aria-labelledby="google-modal-title"
        aria-describedby="google-modal-description"
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Box sx={{ maxWidth: 600 }}>
          <Card p={2}>
            <CardMedia component="img" alt="Google" height="180" image={google} />
            <CardContent>
              <Grid item xs={12}>
                <Input
                  type="file"
                  sx={{ width: '100%' }}
                  required
                  id="credentials"
                  name="credentials"
                  onChange={handleFileChange}
                />
                <Typography variant="body1" color="textSecondary">Upload Google Credentials</Typography>
                <TextField
                    margin="dense"
                    variant="standard"
                    fullWidth
                    name="name"
                    value={formData.name}
                    label="Company Name"
                    placeholder="Company Name"
                    onChange={(e) => handleChange('name', e.target.value)}
                  />

                {['project_id', 'external', 'address', 'website', 'career_site'].map((field) => (
                  <TextField
                    key={field}
                    margin="dense"
                    variant="standard"
                    fullWidth
                    name={field}
                    value={formData[field]}
                    label={field.replace('_', ' ').replace(/\b\w/g, (c) => c.toUpperCase())}
                    placeholder={field.replace('_', ' ')}
                    onChange={(e) => handleChange(field, e.target.value)}
                  />
                ))}
              </Grid>
            </CardContent>
            <CardActions>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleSubmit}
                disabled={isLoading}
              >
                {isLoading ? 'Submitting...' : 'Validate'}
              </Button>
            </CardActions>
            {error && <Typography color="error">Error: {error.message}</Typography>}
          </Card>
        </Box>
      </Modal>
    </Grid>
  );
};

export default JobBoards;
