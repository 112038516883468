import React, { useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 
import { Link as RouterLink, useParams, useNavigate, useLocation } from 'react-router-dom';
import Slide from '@mui/material/Slide';
import { makeStyles } from '@mui/styles';
import { CardContent, Card, Grid, Divider, Box, Menu, MenuItem, InputLabel, Tooltip, DialogTitle, DialogContent, DialogContentText, DialogActions, CircularProgress, Container, useTheme, useMediaQuery, FormControl, Select } from '@mui/material';
// eslint-disable-next-line import/no-unresolved
import { showToast } from 'src/utils/toast';
import { DataGrid } from '@mui/x-data-grid';
import { AutoSizer } from 'react-virtualized';
import JobNotes from '../../../components/Notes/JobNotes';
import { useGetJobeDetailsQuery, useJobStatsQuery, useUpdateJobStatusMutation, useDeleteJobMutation, useAddJobDocumentMutation, useGetJobDocumentQuery, useCloneJobMutation, useDeleteJobDocumentMutation, useDeleteJobAssesmentMutation, useAddJobAssesmentMutation } from '../../../redux/services/jobs/JobServices';
import { useDashStatsQuery } from '../../../redux/dashboard';
import ToDoAppJobs from '../../../components/homePage/ToDoAppJobs';
import { useGetJobInterviewDetailsQuery } from '../../../redux/services/interview/InterviewServices';
import { useGetAssesmentQuery } from '../../../redux/services/main/AssesmentService';
import Iconify from '../../../components/Iconify';
import AssignCandidateJobModel from '../../../components/Mains/AssignCandidateJobModel';
import {useGetCandidateListQuery, useAddMultipleCandidateMutation} from '../../../redux/services/candidate/CandidateServices';
import { useGetAssesmentCategoryQuery } from '../../../redux/services/main/AssesmentCatagoriesservice';
// import { useGetDraftJobDetailsQuery,usePublishDraftJobMutation } from '../../../redux/services/jobs/JobListService';
import PDFViewer from '../candidates/PDFViewer';
import { baseUrl } from '../../../utils/api';
import ShareJobModal from './ShareJobModal';


const useStyles = makeStyles({
  card_heading: {
    display: 'flex',
    justifyContent: 'center',
  },
  description: {
    fontSize: '32px',
    fontWeight: '37px',
  },
  oddRow: {
    backgroundColor: '#f9f9f9',
  },
  evenRow: {
    backgroundColor: '#ffffff',
  },
});

const PerticularJob = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const viewJobType = searchParams.get('viewJob');
  const { id } = useParams()
  const { data, refetch: statsRefetch } = useDashStatsQuery()
  const { data: cardData, refetch: cardRefetch } = useJobStatsQuery(id);
  const [updateJobStatus, updateJobStatusInfo] = useUpdateJobStatusMutation()
  const [addJobDocument, addJobDocumentInfo] = useAddJobDocumentMutation()
  const [deleteJobDocument, deleteJobDocumentInfo] = useDeleteJobDocumentMutation()
  const [addJobAssesment] = useAddJobAssesmentMutation();
  const [deleteJobAssesment] = useDeleteJobAssesmentMutation();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [documentIdToDelete, setDocumentIdToDelete] = useState(null);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [pdfViewerOpen, setPdfViewerOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  const [fileType, setFileType] = useState('');
  const [activeSection, setActiveSection] = useState('');
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [showTopButton, setShowTopButton] = useState(false);
  const [openCreateAssessmentModal, setOpenCreateAssessmentModal] = useState(false);
  const [assessmentType, setAssessmentType] = useState('');
  const [selectedAssessment, setSelectedAssessment] = useState('');
  const [currentAssessment, setCurrentAssessment] = useState(null);
  const [filteredAssessments, setFilteredAssessments] = useState(null);
  const [deleteJob, deleteJobInfo] = useDeleteJobMutation();
  const [cloneJob] = useCloneJobMutation();
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleChangeJobStatus = async (e) => {
    await updateJobStatus({
      job: id,
      status: e
    })
  }
  const { data: jobData, refetch } = useGetJobeDetailsQuery(id);
  const { data: interviewData, refetch :interviewRefetch} = useGetJobInterviewDetailsQuery(id);
  const { data: assesmentCategoryData, refetch: categoryRefetch } = useGetAssesmentCategoryQuery();
  const { data: assesmentData, refetch: refetchAssessment } = useGetAssesmentQuery();
  const { data: jobDocumentData, refetch :jobDocumentDataRefetch} = useGetJobDocumentQuery(id);
  const { data: candidateListData } = useGetCandidateListQuery(); 
  
  const stats = data?.data.find(job => job.job_name === jobData?.title);
  const [textValue, setTextValue] = useState({
    id: '',
    title: '',
    vacancies: '',
    department: '',
    owner: '',
    assesment: '',
    webform: '',
    webform_name: '',
    member_ids: [],
    member_names: [],
    type: '',
    nature: '',
    education: '',
    speciality: '',
    exp_min: '',
    exp_max: '',
    salary_min: '',
    salary_max: '',
    currency: '',
    salary_type: '',
    state: '',
    state_name: '',
    city: '',
    description: '',
    job_boards: '',
    pipeline: '',
    active: '',
    assesment_name: '',
    education_names: '',
    pipeline_name: '',
    owner_name: '',
    department_name: '',
    city_name: '',
  });
  const classes = useStyles();
  const { id: jobId } = useParams();
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [anchorElActions, setAnchorElActions] = React.useState(null);
  const [modelOpen, setModelOpen] = useState(false);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [candidatesModel, setCandidatesModel] = useState(false);
  const [documents, setDocuments] = useState([]);
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [addApplyCandidate, addApplyCandidateInfo] = useAddMultipleCandidateMutation()
  const [selectedCandidate, setSelectedCandidate] = useState(id);
  const handleChangeSelectedCandidate = (e) => setSelectedCandidate(e.target.value)
  const handleUpdateSelectedJob = async () => {
    await addApplyCandidate({
      data: [id],
      id: selectedCandidate,
    })
    refetch()
  }

  const interviewColumns = [
    {
        field: 'actions',
        headerName: 'Actions',
        width: 100,
        renderCell: (params) => (
          <IconButton onClick={() => navigate(`/dashboard/interviews/perticularInterview/${interviewData.list[params.id].id}`)}>
            <Iconify icon="mdi:eye" width={24} height={24} />
          </IconButton>
        ),
    },
    { field: 'title', headerName: 'Title', width: 400, renderCell: (params) => (
        <Button onClick={() => navigate(`/dashboard/interviews/perticularInterview/${interviewData.list[params.id].id}`)}>
          {params.value}
        </Button>
    )},
    { field: 'candidate', headerName: 'Candidate Name', width: 400, renderCell: (params) => (
      <Button onClick={() => navigate(`/dashboard/candidate/perticularCandidate/${interviewData.list[params.id].candidate.id}`)}>
        {params.value}
      </Button>
    )},
    { field: 'time_start', headerName: 'Time Start', width: 250 },
    { field: 'time_end', headerName: 'Time End', width: 250 },
    { field: 'type', headerName: 'Type', width: 250 },
    { field: 'date', headerName: 'Date', width: 250 },
  ];
  
  const interviewRows = interviewData?.list?.map((interview, index) => ({
    id: index,
    title: interview.title,
    candidate: `${interview.candidate.first_name} ${interview.candidate.last_name}`,
    time_start: interview.time_start,
    time_end: interview.time_end,
    type: interview.type,
    date: interview.date,
  })) || [];
  
  const assessmentColumns = [
    {
        field: 'actions',
        headerName: 'Actions',
        width: 100,
        renderCell: (params) => (
            <IconButton onClick={() => navigate(`/dashboard/assessments/assessment-preview`, { state: params.row })}>
              <Iconify icon="mdi:eye" width={24} height={24} />
            </IconButton>
        ),
    },
    { field: 'name', headerName: 'Name', width: 400, renderCell: (params) => (
        <Button onClick={() => navigate(`/dashboard/assessments/assessment-preview`, { state: params.row })}>
        {params.value}
        </Button>
    )},
    { field: 'owner_name', headerName: 'Owner', width: 300 },
    { field: 'category_name', headerName: 'Category', width: 250 },
    {
      field: 'form',
      headerName: 'Questions',
      width: 150,
      renderCell: (params) => (
        <div>{params.value?.length}</div>
      ),
    },
    { field: 'created', headerName: 'Date Created', width: 300 },
  ];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };

  const assessmentRows = assesmentData?.data
  ?.filter(assessment => assessment.id === jobData?.assesment) // Filter assessments
  .map((assessment) => ({
    id: assessment.id,
    name: assessment.name,
    owner_name: assessment.owner_name,
    category_name: assessment.category_name,
    form: assessment.form,
    created: formatDate(assessment.created)
  })) || [];

  const documentColumns = [
    {
      field: 'view',
      headerName: 'View',
      width: 100,
      renderCell: (params) => (
        <IconButton
          onClick={() => handleClickDocument(params.row.url)}
        >
          <Iconify icon="mdi:eye" width={24} height={24} />
        </IconButton>
      ),
    },
    {
      field: 'name',
      headerName: 'Document Name',
      width: 800,
      renderCell: (params) => (
        <Typography
          role="button"
          tabIndex={0}
          style={{ cursor: 'pointer' }}
          color="primary"
          onClick={() => handleClickDocument(params.row.url)}
          onKeyPress={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              handleClickDocument(params.row.url);
            }
          }}
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <Button
          variant="contained"
          onClick={(e) => {
            e.stopPropagation();
            handleDeleteDialogOpen(params.row.id);
          }}
          style={{ textTransform: 'capitalize' }}
        >
          Delete
        </Button>
      ),
    },
  ];
  
  const documentRows = documents.map((doc, index) => ({
    id: index,
    name: doc.name,
    url: doc.url,
  }));

  useEffect(() => {
    if (jobData) {
      setTimeout(() => {
        setLoading(false); // Set loading to false when data is available
      }, 500);
    }
  }, [jobData]);


  useEffect(() => {
    if(addApplyCandidateInfo.isError){
      showToast("error", "Unable to update candidate!")
    }
    if(addApplyCandidateInfo.isSuccess){
      showToast("success", "Successfully updated candidate")
      const timeoutId = setTimeout(() => {
        navigate('/dashboard/candidates');
      }, 2000);
      return () => clearTimeout(timeoutId);
    }
  },[addApplyCandidateInfo,navigate])

  const settings = ['In Progress', 'Filled', 'On Hold', 'Closed'];

  const assignJobModel = () => {
    setModelOpen(true);
  };

  const handleCreateInterview = () => {
    // localStorage.setItem('job_id',id)
    navigate('/dashboard/NewInterview', { state: { jobsId: id }} );
  };

  // const handlePublishDraftJob = async () => {
  //   try {
  //     await publishDraftJob(id);
  //     showToast("success", "Job published successfully!");
  //     // Optionally, refetch data or navigate to another page
  //   } catch (error) {
  //     showToast("error", "Failed to publish job!");
  //   }
  // };

  const handleCloneJob = async () => {
    try {
      // const response = await cloneJob(id).unwrap();

      // showToast("success", "Job cloned successfully!");
      // navigate(`/dashboard/jobs/edit-job/${response.id}`);
      navigate(`/dashboard/jobs/create-job`, { state: { editJobType : 'cloneJob', CloneJobid: id } })
    } catch (error) {
      showToast("error", "Job not published!");
    }
  };

  const handleClickDocument = (documentUrl) => {
    const fileExtension = documentUrl.split('.').pop(); 
    setFileType(fileExtension);
    setPdfUrl(documentUrl);
    setPdfViewerOpen(true);
  };

  const handleClosePdfViewer = () => {
    setPdfViewerOpen(false);
    setPdfUrl('');
  };


  useEffect(() => {
    if (jobDocumentData && jobDocumentData.document) {
      const documentPath = jobDocumentData.document.replace(/^\/+/, '');
      const documentUrl = `${baseUrl}${documentPath}`;
      const documentFilename = jobDocumentData.document.split('/').pop();
      setDocuments([{ url: documentUrl, name: documentFilename }]);
    }else {
        setDocuments([]); 
      }
  }, [jobDocumentData]);

  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   setSelectedFile(file);
  // };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileSelectAndUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('document', file);
      formData.append('jobId', id);

      try {
        const response = await addJobDocument(formData).unwrap();
        const documentPath = response.document.replace(/^\/+/, ''); // Remove leading slashes
        const documentUrl = `${baseUrl}${documentPath}`;
        const documentFilename = response.document.split('/').pop();
        setDocuments([{ url: documentUrl, name: documentFilename }]);
        showToast("success", "Document uploaded successfully!");
        jobDocumentDataRefetch();
        if (fileInputRef.current) {
          fileInputRef.current.value = ''; // Clear the input file field
        }
      } catch (error) {
        showToast("error", "Failed to upload document!");
      }
    } else {
      showToast("error", "No file selected!");
    }
  };

  const handleDeleteDialogOpen = (id) => {
    setDocumentIdToDelete(id);
    setDeleteDialogOpen(true);
  };
  
  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
    setDocumentIdToDelete(null);
  };
  
  const handleDeleteConfirm = async () => {
    if (documentIdToDelete !== null) {
      try {
        await deleteJobDocument({ jobId: id });
        showToast("success", "Document deleted successfully!");
        setDocuments((prevDocuments) => prevDocuments.filter((_, i) => i !== documentIdToDelete));
        jobDocumentDataRefetch();
      } catch (error) {
        showToast("error", "Failed to delete document!");
      }
      handleDeleteDialogClose();
    }
  };
  

  const handleDeleteJob = async () => {
    try {
        await deleteJob(id);
        showToast("success", "Job deleted successfully!");
        navigate('/dashboard/jobs');
    } catch (error) {
        showToast("error", "Failed to delete job!");
    }
  };

  const handleDeleteClickOpen = () => {
    setOpenDeleteModel(true);
  };

  const handleDeleteClose = () => {
    setOpenDeleteModel(false);
  };

  const actionItems = [
    { label: 'Edit job', action: () => navigate(`/dashboard/jobs/edit-job/${id}`) },
    { label: 'Associate candidate', action: assignJobModel },
    { label: 'Schedule interview', action: handleCreateInterview },
    { label: 'Clone job',  action: handleCloneJob },
    { label: 'Delete job', action: handleDeleteClickOpen },
    { label: 'Share This Job', action: () => setShareModalOpen(true) },
  ];

  const handleAllClick = () =>{
    navigate('/dashboard/candidates/', { state: { ids:id}})
  }

  const handleClick = (value, stage, id) => {
    if (value > 0) {
      navigate('/dashboard/candidates/', { state: { ids: id, status: stage } });
    }
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleOpenActionsMenu = (event) => {
    setAnchorElActions(event.currentTarget);
  };
  const handleCloseActionsMenu = () => {
    setAnchorElActions(null);
  };
  
  const assignJobModelClosed = () => {
    setModelOpen(false);
    setCandidatesModel(false);
  };

  const viewAllCandisateshandler = () => {
    setCandidatesModel(true);
  };

  const handleNewCreateAssessment = () => {
    navigate('/dashboard/assessments/create-assessment');
  };

  const handleCreateAssessment = () => {
    setOpenCreateAssessmentModal(true);
  };

  const handleCloseCreateAssessmentModal = () => {
    setOpenCreateAssessmentModal(false);
  };

  const handleAssessmentTypeChange = (event) => {
    setAssessmentType(event.target.value);
    const filteredAssessments = assesmentData?.data?.filter(
      (assessment) => assessment.category === event.target.value
    );
    setFilteredAssessments(filteredAssessments);
  };

  const handleSelectedAssessmentChange = (event) => {
    setSelectedAssessment(event.target.value);  
  };

  const handleSaveAssessment = async () => {
    try {
      await addJobAssesment({
        jobId: id,
        assesmentId: selectedAssessment,
      }).unwrap();
      showToast("success", "Assessment added to job successfully!");
      handleCloseCreateAssessmentModal();
      refetch();
      refetchAssessment();
    } catch (error) {
      showToast("error", "Failed to add assessment to job!");
    }
  };

  const handleRemoveDialogOpen = () => {
    setRemoveDialogOpen(true);
  };
  
  const handleRemoveDialogClose = () => {
    setRemoveDialogOpen(false);
  };
  
  const handleRemoveConfirm = async () => {
    try {
      await deleteJobAssesment({ jobId: jobData.id }).unwrap();
      setCurrentAssessment(null);
      showToast("success", "Assessment removed from job successfully");
      refetch(); 
      refetchAssessment(); 
    } catch (error) {
      showToast("error", "Failed to remove assessment from job");
    }
    handleRemoveDialogClose();
  };

  useEffect(() => {
    if (jobData?.assesment) {
      const assessment = assesmentData?.data?.find(a => a.id === jobData.assesment);
      setCurrentAssessment(assessment);
    }
  }, [jobData, assesmentData]);

  useEffect(() => {
    // setIsLoading(true);
    cardRefetch();
    interviewRefetch();
    refetchAssessment();
    categoryRefetch();
    // setIsLoading(false);
  }, [id,cardRefetch,interviewRefetch,refetchAssessment,categoryRefetch])

  useEffect(() => {
    if (id) {
        refetch();
    }
  }, [id, refetch]);

  useEffect(() => {
    if (updateJobStatusInfo.isError) {
      showToast("error", "Unable to update job status!")
    }
    if (updateJobStatusInfo.isSuccess) {
      showToast("success", "Successfully updated job status!")
      cardRefetch()
      handleCloseUserMenu()
    }
  }, [updateJobStatusInfo,cardRefetch])

  useEffect(() => {
    statsRefetch()
  }, [statsRefetch])

  const handleQuickAccessClick = (sectionId) => {
    setActiveSection(sectionId);
    const element = document.getElementById(sectionId);
    const yOffset = -90; // Adjust this value to set the top margin
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowTopButton(true);
      } else {
        setShowTopButton(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const data = jobData;

    if (data) {
      
      setTextValue({
        id,
        title: data.title || '',
        vacancies: data.vacancies || '',
        department: data.department_name || '',
        owner: data.owner ? `${data.owner.first_name} ${data.owner.last_name}` : '',
        assesment: data.assesment?.id || '',
        webform: data.webform?.id || '',
        webform_name: data.webform?.name || '',
        member_ids: data.members?.map((x) => x.first_name) || [],
        member_names: data.members?.map((x) => x) || [],
        type: data.type || '',
        nature: data.nature || '',
        education: data.educations || '',
        speciality: data.speciality || '',
        exp_min: data.exp_min || '',
        exp_max: data.exp_max || '',
        salary_min: data.salary_min || '',
        salary_max: data.salary_max || '',
        currency: data.currency || '',
        salary_type: data.salary_type || '',
        state: data.state?.id || '',
        state_name: data.locations?.state_name || '',
        city: data.city || '',
        description: data.description || '',
        job_boards: data.job_boards || '',
        pipeline: data.pipeline?.id || '',
        active: data.active || '',
        assesment_name: data.assesment?.name || '',
        education_names: data.educations || '',
        pipeline_name: data.pipeline?.name || '',
        owner_name: `${data.owner_name}` || '',
        department_name: data.department?.name || '',
        city_name: data.locations?.city_name|| '',
        country_name: data.locations?.country_name|| '',
        address: data.locations?.address|| '',
        pincode: data.locations?.pincode|| '',
      });
    }
  }, [jobData, id]);

  const specialities = textValue.speciality || [];
  const specialitiesString = specialities.map(skill => skill).join(', ');

  const pipelineItems = [
    { label: 'Screening', value: stats?.pipeline_stage_stats.Associated_Screening, stage: 'Associated_Screening' },
    // { label: 'Applied', value: stats?.pipeline_stage_stats.Applied, stage: 'Applied' },
    { label: 'Shortlisted', value: stats?.pipeline_stage_stats.Shortlisted, stage: 'Shortlisted' },
    { label: 'Interviews', value: stats?.pipeline_stage_stats.Interview, stage: 'Interview' },
    { label: 'Offered', value: stats?.pipeline_stage_stats.Offered, stage: 'Offered' },
    { label: 'Hired', value: stats?.pipeline_stage_stats.Hired, stage: 'Hired' },
    { label: 'On Board', value: stats?.pipeline_stage_stats.Onboarded, stage: 'Onboarded' },
    { label: 'No of candidates', value: stats?.no_of_candidates, stage: null, onClick: handleAllClick }
  ];

  if (loading) {
    return (
      <Container sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <CircularProgress />
      </Container>
    );
  }
  
  return (
    <div>
      {isSmallScreen ? (
      <Card sx={{ mt: 2 }}>
        <Grid container alignItems="center" spacing={1} sx={{ padding: 1, backgroundColor: '#f9f9f9', position: 'relative' }}>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <IconButton color="secondary" aria-label="close" onClick={() => navigate(-1)}>
              <ArrowBackIosIcon />
            </IconButton>
            <Typography variant="h6" fontWeight="bold" sx={{backgroundColor: 'transparent !important', textDecoration: 'underline'}}>
              {textValue.title}
            </Typography>

            <Tooltip title="Account settings">
              <IconButton onClick={handleOpenActionsMenu} size="small" sx={{ ml: 1 }} aria-haspopup="true">
                <Iconify icon="entypo:dots-three-vertical" width={24} height={24} />
              </IconButton>
            </Tooltip>
            <Menu anchorEl={anchorElActions} open={Boolean(anchorElActions)} onClose={handleCloseActionsMenu}>
              {actionItems.map((item) => (
                <MenuItem key={item.label} onClick={item.action}>
                  {item.label}
                </MenuItem>
              ))}
            </Menu>
          </Grid>
          

          <Grid item xs={12} sm="auto">
            <Button variant="contained" color="primary" onClick={handleOpenUserMenu} fullWidth>
              Job Status{cardData?.data?.job_status && `: ${cardData?.data?.job_status}`}
            </Button>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} value={setting} onClick={() => handleChangeJobStatus(setting)}>
                  {setting}
                </MenuItem>
              ))}
            </Menu>
          </Grid>

          <Grid item xs={12} sm="auto">
            <Button variant="contained" color="primary" onClick={handleAllClick} fullWidth>
              View All Candidates
            </Button>
          </Grid>
        </Grid>
      </Card>
      ) : (
      // <Card sx={{ mt: 2 }}>
      <Grid sx={{ position: 'fixed', top: 100, left: 0, right: 0, zIndex: 1000 }}>
        <Card sx={{ mt: 1 }}>
          <Grid
          container
          alignItems={isSmallScreen ? 'flex-start' : 'center'}
          spacing={1}
          sx={{
            padding: 1,
            backgroundColor: '#f9f9f9',
            position: 'relative',
            flexWrap: isSmallScreen ? 'wrap' : 'nowrap',
          }}
        >
          {!isSmallScreen && (
            <Grid item>
              <IconButton color="secondary" aria-label="close" onClick={() => navigate(-1)}>
                <ArrowBackIosIcon />
              </IconButton>
            </Grid>
          )}

          <Grid item xs>
            <Typography variant="h6" fontWeight="bold" sx={{backgroundColor: 'transparent !important', textDecoration: 'underline'}}>
              {textValue.title}
            </Typography>
          </Grid>

          <Grid item>
            <Button variant="contained" color="primary" fullWidth={isSmallScreen} onClick={handleOpenUserMenu}>
              Job Status{cardData?.data?.job_status && `: ${cardData?.data?.job_status}`}
            </Button>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} value={setting} onClick={() => handleChangeJobStatus(setting)}>
                  {setting}
                </MenuItem>
              ))}
            </Menu>
          </Grid>

          <Grid item>
            <Button variant="contained" color="primary" fullWidth={isSmallScreen} onClick={handleAllClick}>
              View All Candidates
            </Button>
          </Grid>

          {!isSmallScreen && (
            <Grid item>
                <Tooltip title="Account settings">
                <IconButton
                  onClick={handleOpenActionsMenu}
                  size="small"
                  sx={{ ml: 1 }}
                  aria-haspopup="true"
                >
                  <Iconify icon="entypo:dots-three-vertical" width={24} height={24} />
                </IconButton>
              </Tooltip>
            </Grid>
          )}

          <Menu
            anchorEl={anchorElActions}
            open={Boolean(anchorElActions)}
            onClose={handleCloseActionsMenu}
          >
            {actionItems.map((item) => (
              <MenuItem key={item.label} onClick={item.action}>
                {item.label}
              </MenuItem>
            ))}
          </Menu>
        </Grid>
        </Card>
      </Grid>
      )}

    <Card sx={{ mt: 8 }}>
      <Grid item xs={12} sx={{ mt: 2, mb: 2, ml: fullScreen ? 1 : 2 }}>
        <h3 id='education' className=''>Hiring Pipeline</h3>
      </Grid>
      <Divider />
      {fullScreen ? (
          <Select
            fullWidth
            displayEmpty
            value=""
            onChange={(event) => {
              const selectedItem = pipelineItems.find(item => item.label === event.target.value);
              if (selectedItem) {
                if (selectedItem.stage) {
                  handleClick(selectedItem.value, selectedItem.stage, id);
                } else if (typeof selectedItem.onClick === 'function') {
                  selectedItem.onClick();
                }
              }
            }}
          >
            <MenuItem value="" disabled>
              Select Stage
            </MenuItem>
            {pipelineItems.map((item, index) => (
              <MenuItem key={index} value={item.label}>
                {item.label} ({item.value || 0})
              </MenuItem>
            ))}
          </Select>
        ) : (
          <Grid container sx={{ mt: 2, pl: 2, pr: 2, mb: 4 }} spacing={3}>
            {pipelineItems.map((item, index) => (
              <Grid item xs={12} sm={6} md={3} lg={1.7} key={index}>
                <Card sx={{ cursor: 'pointer' }} onClick={() => item.stage ? handleClick(item.value, item.stage, id) : item.onClick()}>
                  <CardContent>
                    <Typography variant="h6" sx={{ display: 'flex', justifyContent: 'center' }}>
                      {item.label}
                    </Typography>
                    <Typography sx={{ fontSize: '32px', fontWeight: '700', textAlign: 'center' }}>
                      {item.value || 0}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
    </Card>

      <Divider variant="middle" />

      <Grid container sx={{ mt: 1 }} spacing={2}>
        <Grid item xs={12} md={3} lg={2}>
          <Card
            sx={{
              width: { xs: '100%', md: '230px' },
              height: 'auto',
              padding: '1%',
              paddingLeft: '1%',
              marginRight: { xs: '0', md: '0' },
              position: { xs: 'relative', md: 'fixed' },
              boxShadow: 'rgba(0, 0, 0, 0.25)',
            }}
          >
            <h3 style={{ textDecoration: 'underline' }}>Quick Access</h3>
            {['jobopeninginfo', 'notes', 'tasklist', 'associatedinterview', 'associatedassessments', 'attchmentdocument'].map((sectionId) => (
              <a
                key={sectionId}
                className={`quickaccess ${activeSection === sectionId ? 'active' : ''}`}
                role="button"
                tabIndex={0}
                style={{ cursor: 'pointer', fontWeight: activeSection === sectionId ? 'bold' : 'normal' }}
                onClick={() => handleQuickAccessClick(sectionId)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    handleQuickAccessClick(sectionId);
                  }
                }}
              >
                {sectionId
                  .replace(/([A-Z])/g, ' $1')
                  .replace(/^./, (str) => str.toUpperCase())
                  .replace('Jobopeninginfo', 'Job Opening Info')
                  .replace('Tasklist', 'Task List')
                  .replace('Associatedinterview', 'Associated Interviews')
                  .replace('Associatedassessments', 'Associated Assessments')
                  .replace('Attchmentdocument', 'Attachment Documents')}
              </a>
            ))}
          </Card>
        </Grid>

        <Grid item xs={12} md={9} lg={10}>
          <Card id='jobopeninginfo' className='job-opening-info' sx={{ p: 2 }}>
          <Grid container direction={isSmallScreen ? "column" : "row"}>
            <Grid item xs={isSmallScreen ? 12 : 10} sx={{ mt: 1, mb: 2 }}>
              <h3 className=''>Job Opening Information</h3>
            </Grid>
            {/* Mobile view: Show Edit Job button at the bottom */}
            {isSmallScreen && (
              <Grid item xs={12} sx={{ textAlign: 'center', mt: 'auto', width : "100%" }}>
                <Button
                  component={RouterLink}
                  to={`/dashboard/jobs/edit-job/${id}`}
                  variant="contained"
                  sx={{ textTransform: 'capitalize', width : "100%" }}
                >
                  Edit Job
                </Button>
              </Grid>
            )}
            {/* Non-mobile view: Show Edit Job button in the same row */}
            {!isSmallScreen && (
              <Grid item xs={2} sx={{ textAlign: 'center' }}>
                <Button
                  component={RouterLink}
                  to={`/dashboard/jobs/edit-job/${id}`}
                  variant="contained"
                  sx={{ textTransform: 'capitalize' }}
                >
                  Edit Job
                </Button>
              </Grid>
            )}
          </Grid>
            <Divider />
            <Grid container sx={{ mt: 4 }} spacing={2}>
              <Grid item xs={12} sx={{ mb: 2 }}>
                <Typography variant="body1">
                  <Grid container>
                    <Grid item xs={4} md={2}>
                      <strong style={{ textAlign: 'right' }}>Job Id:</strong>
                    </Grid>
                    <Grid item xs={8} md={4}>
                      <Box component="span" sx={{ ml: 1 }}>
                        {`Job${String(textValue.id).padStart(4, '0')}` || '-'}
                      </Box>
                      <Divider flexItem />
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>
              <Divider flexItem />
              <Grid item xs={12} md={6} sx={{ mb: 2 }}>
                <Typography variant="body1">
                  <Grid container>
                    <Grid item xs={4}>
                      <strong style={{ textAlign: 'right' }}>Job title:</strong>
                    </Grid>
                    <Grid item xs={8}>
                      <Box component="span" sx={{ ml: 1 }}>
                        {textValue.title || '-'}
                      </Box>
                      <Divider flexItem />
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>
              <Divider flexItem />
              <Grid item xs={12} md={6} sx={{ mb: 2 }}>
                <Typography variant="body1">
                  <Grid container>
                    <Grid item xs={4}>
                      <strong style={{ textAlign: 'right' }}>Number of vacancies:</strong>
                    </Grid>
                    <Grid item xs={8}>
                      <Box component="span" sx={{ ml: 1 }}>
                        {textValue.vacancies || '-'}
                      </Box>
                      <Divider flexItem />
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>
              <Divider flexItem />
              <Grid item xs={12} md={6} sx={{ mb: 2 }}>
                <Typography variant="body1">
                  <Grid container>
                    <Grid item xs={4}>
                      <strong style={{ textAlign: 'right' }}>Department:</strong>
                    </Grid>
                    <Grid item xs={8}>
                      <Box component="span" sx={{ ml: 1 }}>
                        {textValue.department || '-'}
                      </Box>
                      <Divider flexItem />
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>
              <Divider flexItem />
              <Grid item xs={12} md={6} sx={{ mb: 2 }}>
                <Typography variant="body1">
                  <Grid container>
                    <Grid item xs={4}>
                      <strong style={{ textAlign: 'right' }}>Job Owner:</strong>
                    </Grid>
                    <Grid item xs={8}>
                      <Box component="span" sx={{ ml: 1 }}>
                        {textValue.owner || '-'}
                      </Box>
                      <Divider flexItem />
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>
              <Divider flexItem />
              <Grid item xs={12} md={6} sx={{ mb: 2 }}>
                <Typography variant="body1">
                  <Grid container>
                    <Grid item xs={4}>
                      <strong style={{ textAlign: 'right' }}>Team member involved:</strong>
                    </Grid>
                    <Grid item xs={8}>
                      <Box component="span" sx={{ ml: 1 }}>
                        {textValue.member_ids.join(', ') || '-'}
                      </Box>
                      <Divider flexItem />
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>
              <Divider flexItem />
              <Grid item xs={12} md={6} sx={{ mb: 2 }}>
                <Typography variant="body1">
                  <Grid container>
                    <Grid item xs={4}>
                      <strong style={{ textAlign: 'right' }}>Type:</strong>
                    </Grid>
                    <Grid item xs={8}>
                      <Box component="span" sx={{ ml: 1 }}>
                        {textValue.type || '-'}
                      </Box>
                      <Divider flexItem />
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>
              <Divider flexItem />
              <Grid item xs={12} md={6} sx={{ mb: 2 }}>
                <Typography variant="body1">
                  <Grid container>
                    <Grid item xs={4}>
                      <strong style={{ textAlign: 'right' }}>Job Nature:</strong>
                    </Grid>
                    <Grid item xs={8}>
                      <Box component="span" sx={{ ml: 1 }}>
                        {textValue.nature || '-'}
                      </Box>
                      <Divider flexItem />
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>
              <Divider flexItem />
              <Grid item xs={12} md={6} sx={{ mb: 2 }}>
                <Typography variant="body1">
                  <Grid container>
                    <Grid item xs={4}>
                      <strong style={{ textAlign: 'right' }}>Work Experience From:</strong>
                    </Grid>
                    <Grid item xs={8}>
                      <Box component="span" sx={{ ml: 1 }}>
                        {textValue.exp_min || '-'}
                      </Box>
                      <Divider flexItem />
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>
              <Divider flexItem />
              <Grid item xs={12} md={6} sx={{ mb: 2 }}>
                <Typography variant="body1">
                  <Grid container>
                    <Grid item xs={4}>
                      <strong style={{ textAlign: 'right' }}>Minimum Education:</strong>
                    </Grid>
                    <Grid item xs={8}>
                      <Box component="span" sx={{ ml: 1 }}>
                        {textValue.education_names || '-'}
                      </Box>
                      <Divider flexItem />
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>
              <Divider flexItem />
              <Grid item xs={12} md={6} sx={{ mb: 2 }}>
                <Typography variant="body1">
                  <Grid container>
                    <Grid item xs={4}>
                      <strong style={{ textAlign: 'right' }}>Work Experience To:</strong>
                    </Grid>
                    <Grid item xs={8}>
                      <Box component="span" sx={{ ml: 1 }}>
                        {textValue.exp_max || '-'}
                      </Box>
                      <Divider flexItem />
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>
              <Divider flexItem />
              <Grid item xs={12} md={6} sx={{ mb: 2 }}>
                <Typography variant="body1">
                  <Grid container>
                    <Grid item xs={4}>
                      <strong style={{ textAlign: 'right' }}>Salary unit:</strong>
                    </Grid>
                    <Grid item xs={8}>
                      <Box component="span" sx={{ ml: 1 }}>
                        {textValue.salary_type || '-'}
                      </Box>
                      <Divider flexItem />
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>
              <Divider flexItem />
              <Grid item xs={12} md={6} sx={{ mb: 2 }}>
                <Typography variant="body1">
                  <Grid container>
                    <Grid item xs={4}>
                      <strong style={{ textAlign: 'right' }}>Salary From:</strong>
                    </Grid>
                    <Grid item xs={8}>
                      <Box component="span" sx={{ ml: 1 }}>
                        {textValue.salary_min || '-'}
                      </Box>
                      <Divider flexItem />
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>
              <Divider flexItem />
              <Grid item xs={12} md={6} sx={{ mb: 2 }}>
                <Typography variant="body1">
                  <Grid container>
                    <Grid item xs={4}>
                      <strong style={{ textAlign: 'right' }}>Currency:</strong>
                    </Grid>
                    <Grid item xs={8}>
                      <Box component="span" sx={{ ml: 1 }}>
                        {textValue.currency || '-'}
                      </Box>
                      <Divider flexItem />
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>
              <Divider flexItem />
              <Grid item xs={12} md={6} sx={{ mb: 2 }}>
                <Typography variant="body1">
                  <Grid container>
                    <Grid item xs={4}>
                      <strong style={{ textAlign: 'right' }}>Salary To:</strong>
                    </Grid>
                    <Grid item xs={8}>
                      <Box component="span" sx={{ ml: 1 }}>
                        {textValue.salary_max || '-'}
                      </Box>
                      <Divider flexItem />
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>
              <Divider flexItem />
              <Grid item xs={12} md={6} sx={{ mb: 2 }}>
                <Typography variant="body1">
                  <Grid container>
                    <Grid item xs={4}>
                      <strong style={{ textAlign: 'right' }}>Speciality:</strong>
                    </Grid>
                    <Grid item xs={8}>
                      <Box component="span" sx={{ ml: 1 }}>
                        {specialitiesString || '-'}
                      </Box>
                      <Divider flexItem />
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>
              <Divider flexItem />
              <Grid item xs={12} md={6} sx={{ mb: 2 }}>
                <Typography variant="body1">
                  <Grid container>
                    <Grid item xs={4}>
                      <strong style={{ textAlign: 'right' }}>Address:</strong>
                    </Grid>
                    <Grid item xs={8}>
                      <Box component="span" sx={{ ml: 1 }}>
                        {textValue.address || '-'}
                      </Box>
                      <Divider flexItem />
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>
              <Divider flexItem />
              <Grid item xs={12} md={6} sx={{ mb: 2 }}>
                <Typography variant="body1">
                  <Grid container>
                    <Grid item xs={4}>
                      <strong style={{ textAlign: 'right' }}>Country:</strong>
                    </Grid>
                    <Grid item xs={8}>
                      <Box component="span" sx={{ ml: 1 }}>
                       {textValue.country_name || '-'}
                      </Box>
                      <Divider flexItem />
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>
              <Divider flexItem />
              <Grid item xs={12} md={6} sx={{ mb: 2 }}>
                <Typography variant="body1">
                  <Grid container>
                    <Grid item xs={4}>
                      <strong style={{ textAlign: 'right' }}>State:</strong>
                    </Grid>
                    <Grid item xs={8}>
                      <Box component="span" sx={{ ml: 1 }}>
                       {textValue.state_name || '-'}
                      </Box>
                      <Divider flexItem />
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>
              <Divider flexItem />
              <Grid item xs={12} md={6} sx={{ mb: 2 }}>
                <Typography variant="body1">
                  <Grid container>
                    <Grid item xs={4}>
                      <strong style={{ textAlign: 'right' }}>City:</strong>
                    </Grid>
                    <Grid item xs={8}>
                      <Box component="span" sx={{ ml: 1 }}>
                        {textValue.city_name || '-'}
                      </Box>
                      <Divider flexItem />
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>
              <Divider flexItem />
              <Grid item xs={12} md={6} sx={{ mb: 2 }}>
                <Typography variant="body1">
                  <Grid container>
                    <Grid item xs={4}>
                      <strong style={{ textAlign: 'right' }}>Pincode:</strong>
                    </Grid>
                    <Grid item xs={8}>
                      <Box component="span" sx={{ ml: 1 }}>
                        {textValue.pincode || '-'}
                      </Box>
                      <Divider flexItem />
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>
              <Divider flexItem />

              <Divider flexItem />
              <Grid item xs={12} sx={{ mb: 2 }}>
                <strong style={{ textAlign: 'center' }}>Job Description</strong>
                <Box sx={{ padding: 2, border: '1px solid #ccc', borderRadius: 1, overflow: 'auto' }}>
                  <div
                    dangerouslySetInnerHTML={{ __html: textValue?.description || '-' }}
                    style={{ minHeight: '300px', height: 'auto' }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Card>

          <Card id='notes' sx={{ ml: 0, mr: 4, p: 2, mt: 2, width: '100%' }}>
            <JobNotes jobId={jobId} />
          </Card>

          <Card id='tasklist' sx={{ mt:2, ml: 0, mr: 4, p: 2, width: '100%' }}>
            <ToDoAppJobs jobId={jobId}/>
          </Card>

          <Card id='associatedinterview' sx={{ mt:2, ml: 0, mr: 4, p: 2, width: '100%' }}>
            <Box>
              <Grid container direction={isSmallScreen ? "column" : "row"} style={{ display: 'flex', justifyContent: 'space-between'}}>
                  <Grid item xs={isSmallScreen ? 12 : 9} sx={{ mt: 1, mb: 2 }}>
                      <h3 className=''>Associated Interviews</h3>
                  </Grid>
                  {/* Mobile view: Show Create Interview button at the bottom */}
                  {isSmallScreen && (
                    <Grid item xs={12} style={{ textAlign: 'center', mt: 'auto', width : "100%" }}>
                        <Button
                            onClick={handleCreateInterview}
                            variant="contained"
                            style={{ textTransform: 'capitalize', width : "100%" }}
                        >
                            Create Interview
                        </Button>
                    </Grid>
                  )}
                  {/* Non-mobile view: Show Create Interview button in the same row */}
                  {!isSmallScreen && (
                      <Grid item md={2} style={{ textAlign: 'center' }}>
                          <Button
                              onClick={handleCreateInterview}
                              variant="contained"
                              style={{ textTransform: 'capitalize' }}
                          >
                              Create Interview
                          </Button>
                      </Grid>
                  )}
              </Grid>
              <Divider />
              <div style={{ height: 300, width: '100%', marginTop: '30px' }}>
                <AutoSizer>
                  {({ height, width }) => (
                    <DataGrid
                      rows={interviewRows}
                      columns={interviewColumns}
                      pageSize={3}
                      rowsPerPageOptions={[3]}
                      disableSelectionOnClick
                      autoHeight
                      style={{ height, width }}
                      getRowClassName={(params) =>
                          params.indexRelativeToCurrentPage % 2 === 0 ? classes.evenRow : classes.oddRow
                      }
                    />
                  )}
                </AutoSizer>
              </div>
            </Box>
          </Card>
          <Card id='associatedassessments' sx={{ mt:2, ml: 0, mr: 4, p: 2, width: '100%' }}>
            <Box>
            <Grid container direction={isSmallScreen ? "column" : "row"} style={{ display: 'flex', justifyContent :"space-between" }}>
                <Grid item xs={12} md={8} sx={{ mt: 1, mb: 2 }}>
                    <h3 className=''>Associated Assessments</h3>
                </Grid>
                {/* Mobile view: Show buttons at the bottom */}
                {isSmallScreen ? (
                    <Grid item xs={12} sx={{ textAlign: 'center' }}>
                        <Box display="flex" flexDirection="column" justifyContent="space-between" gap={1}>
                            {currentAssessment ? (
                                <Button
                                    onClick={handleRemoveDialogOpen}
                                    variant="contained"
                                    sx={{ textTransform: 'capitalize', width: '100%' }} // Full width for mobile
                                >
                                    Remove Assessment
                                </Button>
                            ) : (
                                <Button
                                    onClick={handleCreateAssessment}
                                    variant="contained"
                                    sx={{ textTransform: 'capitalize', width: '100%' }} // Full width for mobile
                                >
                                    Add Existing Assessment
                                </Button>
                            )}
                            <Button
                                onClick={handleNewCreateAssessment}
                                variant="contained"
                                sx={{ textTransform: 'capitalize', width: '100%' }} // Full width for mobile
                            >
                                New Create Assessment
                            </Button>
                        </Box>
                    </Grid>
                ) : (
                    <Grid item xs={12} md={4} sx={{ textAlign: { xs: 'center', md: 'right' } }}>
                        <Box display="flex" flexDirection="row" justifyContent="space-between" gap={1}>
                            {currentAssessment ? (
                                <Button
                                    onClick={handleRemoveDialogOpen}
                                    variant="contained"
                                    sx={{ textTransform: 'capitalize', width: '48%' }} // Responsive width
                                >
                                    Remove Assessment
                                </Button>
                            ) : (
                                <Button
                                    onClick={handleCreateAssessment}
                                    variant="contained"
                                    sx={{ textTransform: 'capitalize', width: '48%' }} // Responsive width
                                >
                                    Add Existing Assessment
                                </Button>
                            )}
                            <Button
                                onClick={handleNewCreateAssessment}
                                variant="contained"
                                sx={{ textTransform: 'capitalize', width: '48%' }} // Responsive width
                            >
                                New Create Assessment
                            </Button>
                        </Box>
                    </Grid>
                )}
              </Grid>
              <Divider />
                <div style={{ height: 300, width: '100%', marginTop: '30px' }}>
                  <AutoSizer>
                    {({ height, width }) => (
                      <DataGrid
                        rows={assessmentRows}
                        columns={assessmentColumns}
                        pageSize={3}
                        rowsPerPageOptions={[3]}
                        disableSelectionOnClick
                        autoHeight
                        style={{ height, width }}
                        getRowClassName={(params) =>
                          params.indexRelativeToCurrentPage % 2 === 0 ? classes.evenRow : classes.oddRow
                        }
                      />
                    )}
                  </AutoSizer>
                </div>
            </Box>
          </Card>
          <Card id='attchmentdocument' sx={{ mt:2, ml: 0, mr: 4, p: 2, width: '100%' }}>
            <Box>
            <Grid container direction={isSmallScreen ? "column" : "row"} style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Grid item xs={isSmallScreen ? 12 : 9} md={8} sx={{ mt: 1, mb: 2 }}>
                  <h3 className=''>Attachment Documents</h3>
              </Grid>
              {/* Mobile view: Show Choose File button at the bottom */}
              {isSmallScreen ? (
                  <Grid item xs={12} sx={{ textAlign: 'center' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <input
                              type="file"
                              onChange={(event) => {
                                  handleFileSelectAndUpload(event);
                              }}
                              ref={fileInputRef} 
                              style={{ display: 'none' }}
                          />
                          <Button
                              variant="contained"
                              onClick={handleButtonClick}
                              sx={{ marginLeft: 'auto', width: '100%' }} // Full width for mobile
                          >
                              Choose File
                          </Button>
                      </Box>
                  </Grid>
              ) : (
                  <Grid item md={4} style={{ textAlign: 'center' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <input
                              type="file"
                              onChange={(event) => {
                                  handleFileSelectAndUpload(event);
                              }}
                              ref={fileInputRef} 
                              style={{ display: 'none' }}
                          />
                          <Button
                              variant="contained"
                              onClick={handleButtonClick}
                              sx={{ marginLeft: 'auto' }} // Align button to the right
                          >
                              Choose File
                          </Button>
                      </Box>
                  </Grid>
                )}
              </Grid>
              <Divider />
              <div style={{ height: 200, width: '100%', marginTop: '30px' }}>
                <AutoSizer>
                  {({ height, width }) => (
                    <DataGrid
                      rows={documentRows}
                      columns={documentColumns}
                      pageSize={3}
                      rowsPerPageOptions={[3]}
                      disableSelectionOnClick
                      autoHeight
                      style={{ height, width }}
                      getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? classes.evenRow : classes.oddRow
                      }
                    />
                  )}
                </AutoSizer>
              </div>
            </Box>
          </Card>
        </Grid>
      </Grid>

      <AssignCandidateJobModel
        open={modelOpen}
        handleClose={assignJobModelClosed}
        candidates={candidateListData}
        value={selectedCandidate}
        handleChange={handleChangeSelectedCandidate}
        handleSubmit={handleUpdateSelectedJob}
        name="Candidate"
      />

      <Dialog
        open={openDeleteModel}
        onClose={handleDeleteClose}
        aria-labelledby="responsive-dialog-title"
        sx={{ '& .MuiDialog-paper': { height: '200px' } }}
      >

        <DialogTitle>{'Delete'}</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this job?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose}>Disagree</Button>
          <Button onClick={handleDeleteJob} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog 
        open={openCreateAssessmentModal} 
        onClose={handleCloseCreateAssessmentModal} 
        sx={{ '& .MuiDialog-paper': { height: '50%', maxHeight: '80vh' } }}
        >
        <DialogTitle>Pre-Screening Assessment</DialogTitle>
        <DialogContent>
          <p>Note: This Pre-Screening assessments will be displayed to candidates while applying for this job opening. You can also manually share this assessments with candidates via mail.</p>
          <FormControl fullWidth margin="normal">
            <InputLabel>Category Type</InputLabel>
            <Select
              value={assessmentType}
              onChange={handleAssessmentTypeChange}
              label="Category Type"
              MenuProps={{
                PaperProps: {
                    style: {
                        maxHeight: 48 * 4 + 8, 
                        width: 250,
                    },
                },
              }}
            >
              <MenuItem value=""><em>Select</em></MenuItem>
              {assesmentCategoryData?.data?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel>Assessment</InputLabel>
            <Select
              value={selectedAssessment}
              onChange={handleSelectedAssessmentChange}
              label="Assessment"
              MenuProps={{
                PaperProps: {
                    style: {
                        maxHeight: 48 * 4 + 8, 
                        width: 250,
                    },
                },
              }}
            >
              <MenuItem value=""><em>Select</em></MenuItem>
              {filteredAssessments?.map((assessment) => (
                <MenuItem key={assessment.id} value={assessment.id}>
                  {assessment.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCreateAssessmentModal}>Cancel</Button>
          <Button onClick={handleSaveAssessment}>Save</Button>
        </DialogActions>
      </Dialog>

      {pdfUrl && (
        <PDFViewer open={pdfViewerOpen} onClose={handleClosePdfViewer} fileUrl={pdfUrl} fileType={fileType} />
      )}
      {/* <ViewAllCandidatesModel open={candidatesModel} handleClose={assignJobModelClosed} /> */}
      {showTopButton && (
        <Button
          onClick={handleScrollToTop}
          variant="contained"
          color="primary"
          style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            zIndex: 1000,
          }}
        >
          <ArrowUpwardIcon />
        </Button>
      )}
      <Dialog
        open={removeDialogOpen}
        onClose={handleRemoveDialogClose}
        sx={{ '& .MuiDialog-paper': { minHeight: '200px' } }} // Adjust the height as needed
      >
        <DialogTitle>Remove Assessment</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to remove this assessment from the job?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRemoveDialogClose} color="primary">
            Disagree
          </Button>
          <Button onClick={handleRemoveConfirm} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteDialogClose}
        sx={{ '& .MuiDialog-paper': { minHeight: '200px' } }} // Adjust the height as needed
      >
        <DialogTitle>Delete Document</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this document?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose} color="primary">
            Disagree
          </Button>
          <Button onClick={handleDeleteConfirm} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>

      <ShareJobModal
        open={shareModalOpen}
        onClose={() => setShareModalOpen(false)}
        jobId={id}
        jobTitle={textValue.title}
      />

    </div>
  );
};

export default PerticularJob;
