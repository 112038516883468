import * as Yup from 'yup';
import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { useDispatch } from 'react-redux';
// material
import { Link, Stack, Checkbox, TextField, IconButton, InputAdornment, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import Iconify from '../../../components/Iconify';
import { useAddLoginMutation } from '../../../redux/services/login/LoginService';
import { authTokenAction, authAction,authAccountAction } from '../../../redux/auth/AuthReducer';
import { showToast } from '../../../utils/toast';

// ----------------------------------------------------------------------
export default function LoginForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [AddLogin, AddLoginInfo] = useAddLoginMutation();


  // if (AddLoginInfo.isError) {
  //   console.log(AddLoginInfo.data,"error h bhai")

  //   AddLoginInfo.reset();
  // }
  const successToast = async () => {
    await showToast("success", "Welcome to edjobster !! ")

  }
  const errorToast = async () => {
   await showToast("error", "Invalid username and password !! ")

  }

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    username: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const removeAllCookies = () => {
    document.cookie.split(";").forEach((cookie) => {
      const name = cookie.split("=")[0].trim();
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    });
  };

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      // localStorage.removeItem("globalUser");
      // sessionStorage.removeItem("globalUser");
      localStorage.clear();
      sessionStorage.clear();
      removeAllCookies()
      await AddLogin({
        username: values.username,
        password: values.password
      })
      dispatch(authAction(true));

    },
  });

  const { errors, touched, values, isSubmitting, handleChange, handleSubmit, getFieldProps, resetForm, initialValues, setSubmitting } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  useEffect(() => {
    const handleLoginResponse = async () => {
      setSubmitting(false);
      if (AddLoginInfo.isError) {
        const errorMessage = AddLoginInfo.error ? AddLoginInfo.error.data.msg : "Retry";
        // console.log("error", errorMessage);

        // Check for 403 error code
        if (AddLoginInfo.error && AddLoginInfo.error.data.code === 403) {
          await showToast("error", "Your account is not verified. Please verify your email address");
        } else {
          await errorToast();
        }

        resetForm(initialValues);
      }
      if (AddLoginInfo.isSuccess) {
        dispatch(authTokenAction(AddLoginInfo.data.access));
        dispatch(authAccountAction(AddLoginInfo.data.account.company_id));
        // console.log("success", AddLoginInfo.data);
        localStorage.setItem("globalUser", JSON.stringify(AddLoginInfo.data));
        sessionStorage.setItem("globalUser", JSON.stringify(AddLoginInfo.data));
        await successToast();
        navigate('/dashboard/app', { replace: true });
      }
    };

    handleLoginResponse();
  }, [AddLoginInfo, dispatch, initialValues, navigate, resetForm, setSubmitting]);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            value={values.username}
            onChange={handleChange}
            {...getFieldProps('username')}
            error={Boolean(touched.username && errors.username)}
            helperText={touched.username && errors.username}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          />

          <Link component={RouterLink} variant="subtitle2" to="/auth/forgot-password/" underline="hover" sx={{backgroundColor: 'transparent !important'}}>
            Forgot password?
          </Link>
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={AddLoginInfo.isLoading && isSubmitting} sx={{marginTop:"10%"}}>
          Login
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
