import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useTheme } from '@mui/material/styles';
// material
import { Card, Stack, Button, Container, Typography, ListItemIcon, DialogContent, Grid, TableContainer } from '@mui/material';
import { showToast } from '../../../utils/toast';
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import AddNewContact from './components/AddNewContact';
import {
  useCreateContactMutation,
  useDeleteContactMutation,
  useGetAllContactQuery,
  useUpdateContactMutation,
} from '../../../redux/services/settings/contactService';
import ConfirmationDialog from '../../main/ConfirmationDialog';

function Contacts() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState('Add');
  const [AddContact, AddContactInfo] = useCreateContactMutation();
  const [textboxlabel, setTextboxlabel] = useState(false);
  const [stageApidata, setStageApidata] = useState({
    name: '',
    fileds: [],
  });
  const { data: contactData, isLoading, refetch } = useGetAllContactQuery();
  const [deleteContact, deleteContactInfo] = useDeleteContactMutation();
  const [particularData, setParticularData] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [idsToDelete, setIdsToDelete] = useState([]);

  const initialFormData = {
    id: null,
    name: '',
    email: '',
    mobile: '',
  };
  // const [contactData,setContactData]=useState([]);

  const handleDeleteSelected = async () => {    
    try {
      await Promise.all(idsToDelete.map(id => deleteContact(id)));
      showToast('success', 'Selected Contacts deleted successfully');
      refetch();
    } catch (error) {
      showToast('error', 'Failed to delete selected Contacts');
    } finally {
      setOpenDeleteDialog(false);
      setIdsToDelete([]);
    }
  };

  const options = {
    filterType: 'dropdown',
    responsive: 'standard', // or 'vertical', 'simple'
    filter: true,
    download: true,
    print: true,
    // selectableRows: 'multiple',
    rowsPerPage: 10, // Adjust as needed
    rowsPerPageOptions: [10, 20, 30], // Adjust as needed
    // customToolbarSelect: () => {}, // Customize toolbar if needed
    viewColumns: true,
    onRowsDelete: (rowsDeleted) => {
      const ids = rowsDeleted.data.map(row => contactData?.data[row.dataIndex].id);
      setIdsToDelete(ids);
      setOpenDeleteDialog(true);
    },
  };  

  useEffect(() => {
    refetch();
  },[]);

  const addNewContactHandler = () => {
    setTextboxlabel(true);
    clearFormData();
    setModalOpen(true);
    setModalType('Add');
  };
  const modalHandleClose = () => {
    setModalOpen(false);
    clearFormData();
    // setEditModalOpen(false);
  };
  const navigateCancel = () => {
    navigate('/dashboard/InstituteSettings/settings');
  };

  const [active, setActive] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const handleeClosee = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setActive(false);
  };
  const handleClosee = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const clearFormData = () => {
    setParticularData(initialFormData); 
  };

  const onSubmitHandler = async (value) => {
    const res = await AddContact(value);
    const api = await res?.data?.code;
    if (api === 200) {
      showToast('success', value?.id ? 'Contact Updated Successfully' : 'Contact Added Successfully');
      refetch();
      clearFormData();
      setModalOpen(false);
    } else if (res?.error?.data?.code === 400) {
      showToast('error', res?.error?.data?.msg);
    } else {
      showToast('error', 'Something went wrong');
    }
    // }
  };

  // const fetchData = async () => {
  // };

  return (
    <Page>
      <Container>
        <Stack direction={isMobile ? "column" : "row"} alignItems="center" justifyContent="space-between" mb={5} style={{ paddingLeft: "0px" }} sx={{ marginBottom:'0px' }}>
          <Container
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              paddingLeft: "0px",
            }}
          >
            <div className="backbutton tt-back" style={{ display: 'flex' }}>
              <ArrowBackIosIcon
                onClick={navigateCancel}
                sx={{
                  cursor: 'pointer',
                }}
              />
            </div>
            {/* <h1>Contacts</h1> */}
            <Typography 
              variant="h3" 
              color="textPrimary" 
              sx={{ 
                width: '300px',
                textDecorationLine: 'underline',
                backgroundColor: 'transparent !important'
              }}
            >
              Contacts
            </Typography>
          </Container>
          <Button
            style={{ width: isMobile ? "100%" : "200px", height: "46px", marginTop: isMobile ? "10px" : "", marginBottom: isMobile ? "10px" : "" }}
            variant="contained"
            component={RouterLink}
            to="#"
            onClick={addNewContactHandler}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            New Contact
          </Button>
        </Stack>

        <Grid item xs={12} md={12}>
            <Card>
              <TableContainer>
                <MUIDataTable
                  title={'Contacts List'}
                  data={contactData?.data}
                  columns={[
                    {
                      name: 'id',
                      label: 'Id',
                      options: {
                        filter: true,
                        sort: true,
                      },
                    },
                    {
                      name: 'name',
                      label: 'Name',
                      options: {
                        filter: true,
                        sort: true,
                      },
                    },
                    {
                      name: 'email',
                      label: 'Email',
                      options: {
                        filter: true,
                        sort: true,
                      },
                    },
                    {
                      name: 'mobile',
                      label: 'Phone',
                      options: {
                        filter: true,
                        sort: true,
                      },
                    },
                    {
                      name: 'action',
                      label: 'Action',
                      options: {
                        filter: true,
                        sort: true,
                        customBodyRender: (value, tableMeta, updateValue) => (
                            <>
                              <Snackbar
                                open={open}
                                autoHideDuration={3000}
                                onClose={handleeClosee}
                                sx={{ height: '10vh' }}
                                anchorOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right',
                                  position: 'absolute',
                                }}
                              >
                                <Alert onClose={handleeClosee} severity="success" sx={{ width: '100%' }}>
                                  Interview Deleted!
                                </Alert>
                              </Snackbar>
                                <Dialog
                                  open={open}
                                  onClose={handleClosee}
                                  aria-labelledby="responsive-dialog-title"
                                  sx={{ '& .MuiDialog-paper': { minHeight: '200px' } }}
                                >
                                  <DialogTitle variant="h4">{'Delete'}</DialogTitle>
                                  <DialogContent>
                                    <DialogContentText>Are you sure you wan&#39;t to delete this contact?</DialogContentText>
                                  </DialogContent>
                                  <DialogActions>
                                    <Button autoFocus onClick={handleClosee}>
                                      Disagree
                                    </Button>
                                    <Button
                                      onClick={() =>
                                        deleteContact(tableMeta.rowData?.[0])
                                          .unwrap()
                                          .then((res) => {
                                            if (res?.code === 200) {
                                              showToast('success', 'Contact Deleted Successfully');
                                              setActive(true);
                                              setOpen(false)
                                              refetch();
                                            } else {
                                              showToast('error', 'Something went wrong');
                                            }
                                          })
                                      }
                                      autoFocus
                                    >
                                      Agree
                                    </Button>
                                  </DialogActions>
                                </Dialog>
                            
                              <Stack direction="row" spacing={1} style={{ justifyContent: 'center' }}>
                                <Button
                                  style={{ minWidth: 0 }}
                                  // type="submit"
                                  onClick={() => {
                                    setModalOpen(true);
                                    setTextboxlabel(false);
                                    // setParticularData(tableMeta.rowData)
                                    setParticularData({
                                      id: tableMeta.rowData?.[0],
                                      name: tableMeta.rowData?.[1],
                                      email: tableMeta.rowData?.[2],
                                      mobile: tableMeta.rowData?.[3],
                                    });
                                  }}
                                >
                                  <ListItemIcon style={{ color: '#fff', padding: '0px', minWidth: 0 }}>
                                    <Iconify icon="ep:edit" width={24} height={24} color={'blue'} />
                                  </ListItemIcon>
                                </Button>
                                <Button
                                  style={{ minWidth: 0, margin: '0px 5px' }}
                                  color="error"
                                  onClick={handleClickOpen}
                                >
                                  <ListItemIcon style={{ color: '#fff', padding: '0px', minWidth: 0 }}>
                                    <Iconify icon="eva:trash-2-outline" width={24} height={24} color={'red'} />
                                  </ListItemIcon>
                                </Button>
                              </Stack>
                            </>
                          ),
                      },
                    },
                  ]}
                  options={options}
                />
              </TableContainer>
            </Card>
          </Grid>

        
      </Container>
      {/* <SettingModalAddress 
        open={modalOpen} 
        handleClose={modalHandleClose} 
        // formData={addData} 
        type={modalType} 
        // onSubmitData={onSubmitHandler} 
      /> */}
      <AddNewContact
        open={modalOpen}
        handleClose={modalHandleClose}
        textboxlabel={textboxlabel ? 'Add Contact' : 'Edit Contact'}
        id="contact"
        name="Add Contact"
        buttonLabel="Add Contact"
        onsubmit={onSubmitHandler}
        data={particularData}
        type={modalType}
        formstagedata={stageApidata}
      />
      <ConfirmationDialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        title="Delete"
        message="Are you sure you want to delete the selected Contacts?"
        onConfirm={handleDeleteSelected}
      />
    </Page>
  );
}

export default Contacts;
