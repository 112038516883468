import { apiBasePath } from '../BaseUrl';
import { apiUrl } from '../../../utils/api';

const extendedApi = apiBasePath.injectEndpoints({
  endpoints: (build) => ({
    getJobBoardList: build.query({
      query: () => ({
        url: `${apiUrl.jobs}job-board/`,
      }),
    }),
    jobBoard: build.mutation({
        query: (data) => ({
            url: `${apiUrl.jobs}job-board/`,
            method: 'POST',
            body: data,
            headers: {
                'Authorization': `Bearer ${JSON.parse(localStorage.getItem("globalUser")).access}`
            }
        }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetJobBoardListQuery,
  useJobBoardMutation
} = extendedApi;
