import React from 'react';
// import CreateJobBoards from '../../../settings/job-boards/CreateJobBoards';
import JobBoards from '../../../settings/job-boards/JobBoards';


const SelectJobBoards = () => (
    <>
    {/* <CreateJobBoards/> */}
    <JobBoards/>
    
    </>
  );
export default SelectJobBoards;
