import { faker } from '@faker-js/faker';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate, useLocation, useParams } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Stack, Card, Avatar, Button, useMediaQuery, Paper, Badge } from '@mui/material';
import { useDashStatsQuery } from '../redux/dashboard';
import ToDoApp from '../components/homePage/ToDoApp';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
import ChartsGraph from './ChartsGraph';
import LatestInterview from '../components/homePage/InterviewStats/LatestInterview';
import "./Login.css";
import HireVector from "../assets/images/hire-vector.png";
import ViewAllCandidatesModel from "../components/Mains/ViewAllCandidatesModel"
import { useGetDailyJobsCountQuery } from '../redux/services/jobs/JobServices';
import AgeofJob from '../components/homePage/AgeofJobStats/AgeofJob';


// ----------------------------------------------------------------------

export default function DashboardApp() {
  const { id: candidateId } = useParams();
  const userData = JSON.parse(localStorage.getItem("globalUser"))?.account
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useSelector((state) => state.login.auth);
  const { data, refetch } = useDashStatsQuery()
  const { data: dailyJobsData , refetch: dailyJobsRefetch } = useGetDailyJobsCountQuery(); 
  const handleClick = (value, stage, id) => {
    if (value > 0) {
      navigate('/dashboard/candidates/', { state: { ids: id, status: stage } })
    }
  }
  useEffect(() => {
    refetch();
    dailyJobsRefetch();
  }, []);

  const pipelineStage = data?.data?.map((e) => ({
    Applied: e.pipeline_stage_stats?.Applied || 0,
    Associated_Screening: e.pipeline_stage_stats?.Associated_Screening || 0,
    Hired: e.pipeline_stage_stats?.Hired || 0,
    Interview: e.pipeline_stage_stats?.Interview || 0,
    Offered: e.pipeline_stage_stats?.Offered || 0,
    Onboarded: e.pipeline_stage_stats?.Onboarded || 0,
    Shortlisted: e.pipeline_stage_stats?.Shortlisted || 0,
  })) || [];

  const pipelineStageSummedData = pipelineStage.reduce((acc, curr) => {
    Object.keys(curr).forEach(key => {
      acc[key] = (acc[key] || 0) + curr[key];
    });
    return acc;
  }, {});

  const pipelineStagechartdata = Object.keys(pipelineStageSummedData).map((key) => ({
    label: key,
    value: pipelineStageSummedData[key] // Use the value corresponding to the key
  })) || []

  const pipelineStageInterviewchartdata = dailyJobsData?.job_stats?.map((e) => ({
    label: e.job_name,
    value: e.pipeline_stage_stats, // Assuming you want to use a different property for hrs
  })) || [];

  const stages = [
    { name: 'Associated_Screening', label: 'Screening', color: '#D7EAFF' },
    { name: 'Shortlisted', label: 'Shortlisted', color: '#FFD0E9' },
    { name: 'Interview', label: 'Interviews', color: '#FFF3B3' },
    { name: 'Offered', label: 'Offered', color: '#CBFFC7' },
    { name: 'Hired', label: 'Hired', color: '#B2E0B2' },
    { name: 'Onboarded', label: 'On Board', color: '#ffb8a9' },
  ];



  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <h1>
          Hi {(userData?.first_name || "Test") + (` ${userData?.last_name}` || " ")} !, Welcome back
        </h1>
        {/* <div className="container">
          <h1>Stats</h1>
          <div className="row">
            <div className="card">
              <AppWidgetSummary title="Screening" total={data?.data?.pipeline_stage_status_stats?.screening} icon={'material-symbols:laptop-chromebook-outline'} />
            </div>
            <div className="card">
              <AppWidgetSummary title="Applied" total={data?.data?.pipeline_stage_status_stats?.applied} color="info" icon={'material-symbols:featured-play-list-rounded'} />
            </div>
            <div className="card">
              <AppWidgetSummary title="Shortlisted" total={data?.data?.pipeline_stage_status_stats?.shortlisted} color="warning" icon={'material-symbols:select-check-box'} />
            </div>
            <div className="card">
              <AppWidgetSummary title="Interviews" total={data?.data?.pipeline_stage_status_stats?.interview} color="error" icon={'mdi:people'} />
            </div>
            <div className="card">
              <AppWidgetSummary title="Offered" total={data?.data?.pipeline_stage_status_stats?.offered} icon={'mdi:offer'} />
            </div>
            <div className="card">
              <AppWidgetSummary title="Hired" total={data?.data?.pipeline_stage_status_stats?.hired} color="warning" icon={'material-symbols:work'} />
            </div>
            <div className="card">
              <AppWidgetSummary title="On Boarded" total={data?.data?.pipeline_stage_status_stats?.on_boarded} color="info" icon={'material-symbols:cloud-done-rounded'} />
            </div>
          </div>
        </div> */}
            <Stack className='container' spacing={2}>
              <Grid container spacing={1} alignItems="center" justifyContent={isMobile ? 'center' : 'flex-start'}>
                <Grid item xs={12} sm={2}  sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography variant="subtitle1">JOBS</Typography>
                </Grid>
                {stages.map((stage, index) => (
                  <Grid item xs={4} sm={1.5} key={index}>
                    <Paper elevation={1} sx={{ backgroundColor: stage.color, textAlign: 'center', padding: '8px' }}>
                      <Typography variant="body2">{stage.label}</Typography>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
              <div className='grid-scroller' style={{ maxHeight: '400px', overflowY: 'auto' }}>
                <Stack spacing={1}>
                  {data?.data?.map((item) => (
                    <Paper key={item.job_id} elevation={2} sx={{ padding: '12px', borderRadius: '12px' }}>
                      <Grid container alignItems="center" spacing={1}>
                        <Grid item xs={12} sm={2} sx={{ textAlign: 'center' }}>
                          <Button variant="text" onClick={() => navigate(`/dashboard/candidate/jobDetails/${item.job_id}`)}>
                            {item.job_name} ({item.no_of_candidates})
                          </Button>
                        </Grid>
                        {stages.map((stage, index) => (
                          <Grid item xs={4} sm={1.5} key={index} sx={{ textAlign: 'center' }}>
                            <Badge color="primary" badgeContent={item.pipeline_stage_stats[stage.name.replace(/ /g, '')] || 0}>
                              <Button
                                variant="outlined"
                                fullWidth
                                onClick={() => handleClick(item.pipeline_stage_stats[stage.name.replace(/ /g, '')], stage.name, item.job_id)}
                                sx={{ borderColor: stage.color, borderWidth: '2px' }}
                              >
                                {item.pipeline_stage_stats[stage.name.replace(/ /g, '')] || 0}
                              </Button>
                            </Badge>
                          </Grid>
                        ))}
                      </Grid>
                    </Paper>
                  ))}

                  {(!data || !data.data || data.data.length === 0) && (
                    <Stack justifyContent="center" alignItems="center" sx={{ marginTop: '50px' }}>
                      <Button variant="contained" onClick={() => navigate('/dashboard/jobs/create-job')}>Create New Job</Button>
                    </Stack>
                  )}
                </Stack>
              </div>
            </Stack>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={8} mt={5}>
            <Card>
              <ChartsGraph />
            </Card>
          </Grid>

          <Grid item xs={12} md={6} lg={4} mt={5}>
            <AppCurrentVisits
              title="Jobs Status"
              chartData={pipelineStagechartdata}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.chart.blue[0],
                theme.palette.chart.violet[0],
                theme.palette.chart.yellow[0],
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates
              title="Jobs Application" 
              // subheader="(+43%) than last year"
              chartData={pipelineStageInterviewchartdata}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4} s>
            <AppCurrentSubject
              title="Current Subject"
              chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
              chartData={[
                { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },
                { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
                { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
              ]}
              chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={8}>
            <Card sx={{
              minHeight: "100%",
            }}>
              <LatestInterview />
            </Card>
          </Grid>
          {/* <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate
              title="News Update"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: faker.name.jobTitle(),
                description: faker.name.jobTitle(),
                image: `/static/mock-images/covers/cover_${index + 1}.jpg`,
                postedAt: faker.date.recent(),
              }))}
            />
          </Grid> */}

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline
              title="Order Timeline"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: [
                  'New Job Posted',
                  '12 Candidates applied',
                  'Application closed',
                  '5 Shortlisted',
                  '1 candidate hired',
                ][index],
                type: `order${index + 1}`,
                time: faker.date.past(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AgeofJob/>
          </Grid>
          <Grid item xs={12} md={6} lg={8}>
            <Card sx={{
              minHeight: "100%",
            }}>
              <ToDoApp candidateId={candidateId}/>
            </Card>
          </Grid>
          {/* <Grid item xs={12} md={6} lg={8}>
            <Card sx={{
              minHeight: "100%",
            }}>
              <LatestInterview />
            </Card>
          </Grid> */}
        </Grid>
      </Container >
    </Page >
  );
}
