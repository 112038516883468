import React, { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Button from '@mui/material/Button';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { AppBar, Box, Card, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, InputLabel, List, ListItem, ListItemText, Menu, MenuItem, TextField, Toolbar, Tooltip, useMediaQuery } from '@mui/material';
import ReactQuill from 'react-quill';
import { showToast } from "../../../utils/toast";
import { useDeleteInterviewMutation, useGetInterviewDetailsQuery } from "../../../redux/services/interview/InterviewServices"
import { useGetEmailTamplateQuery } from '../../../redux/services/settings/EmailTamplateService';
import { useGetLocationQuery } from '../../../redux/services/settings/LocationService';
import Iconify from '../../../components/Iconify';


const PriviewInterview = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const isPriviewId = Boolean(id);

  const { data: interviewData, refetch: interviewDataRefetch } = useGetInterviewDetailsQuery(id, { skip: !isPriviewId });
  const { data: emailTemplateData, refetch: emailTemplateDataRefetch } = useGetEmailTamplateQuery()
  const { data: locationData, refetch: locationDataRefetch } = useGetLocationQuery()
  const [deleteInterview, deleteInterviewInfo] = useDeleteInterviewMutation();
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [anchorElActions, setAnchorElActions] = React.useState(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  useEffect(() => {
    emailTemplateDataRefetch();
  },[]);

  const getEmailTemplateName = (templateId) => {
    const template = emailTemplateData?.data?.find(template => template.id === templateId);
    return template ? template.subject : '';
  };

  const getLocationName = (locationId) => {
    const location = locationData?.data?.find(location => location.id === locationId);
    return location ? location.name : '';
  };

  const handleDeleteJob = async () => {
    try {
      await deleteInterview(id);
      showToast("success", "Interview deleted successfully!");
      navigate('/dashboard/interviews');
    } catch (error) {
        showToast("error", "Failed to delete Interview!");
    }
  };
  
    const handleDeleteClickOpen = () => {
      setOpenDeleteModel(true);
    };
  
    const handleDeleteClose = () => {
      setOpenDeleteModel(false);
    };

  const actionItems = [
    { label: 'Edit Interview', action: () => navigate(`/dashboard/interviews/edit-interview/${id}`) },
    // { label: 'Associate candidate', action: assignJobModel },
    // { label: 'Schedule interview', action: handleCreateInterview },
    // { label: 'Clone job',  action: handleCloneJob },
    { label: 'Delete', action: handleDeleteClickOpen },
  ];

  const handleOpenActionsMenu = (event) => {
    setAnchorElActions(event.currentTarget);
  };
  const handleCloseActionsMenu = () => {
    setAnchorElActions(null);
  };

  useEffect(() => {
    if (isPriviewId) {
      interviewDataRefetch();
    }
  }, [id, isPriviewId, interviewDataRefetch]);

  return (
    <div>
      <Grid sx={{ position: 'fixed', top: 100, left: 0, right: 0, zIndex: 1000 }}>
        <Card sx={{ position: 'relative' }} style={{ backgroundColor: '#f9fafb' }}>
          <Toolbar>
          <IconButton
                edge="start"
                color="secondary"
                aria-label="close"
              >
                <ArrowBackIosIcon onClick={() => (navigate(-1))} />
              </IconButton>
              
            <Grid container spacing={3}  alignItems="center">
              {/* <Grid item md={10} style={{ display: 'flex', alignItems: 'center' }}>
                <h2 style={{
                  color: "black",
                  width: "400px",
                  marginRight: "50px"
                }}>
                  {interviewData?.data?.title || ''}
                </h2>
              </Grid> */}
              <Grid item xs={10} md={10}>
                <Typography variant="h5" sx={{ color: 'black', marginRight: 2, backgroundColor: 'transparent !important'}}>
                  {interviewData?.data?.title || ''}
                </Typography>
              </Grid>
              {/* <Grid item md={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box sx={{ display: 'flex', justifyContent: "flex-end" }}>
                  <Tooltip title="Account settings">
                    <IconButton
                      onClick={handleOpenActionsMenu}
                      size="small"
                      sx={{ ml: 1 }}
                      // aria-controls={open ? 'account-menu' : undefined}
                      aria-haspopup="true"
                      // aria-expanded={open ? 'true' : undefined}
                    >
                      <Iconify icon="entypo:dots-three-vertical" width={24} height={24} />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Menu
                  anchorEl={anchorElActions}
                  open={Boolean(anchorElActions)}
                  onClose={handleCloseActionsMenu}
                >
                  {actionItems.map((item) => (
                    <MenuItem key={item.label} onClick={item.action}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Menu>
              </Grid> */}
              <Grid item xs={2} md={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Tooltip title="Account settings">
                  <IconButton
                    onClick={handleOpenActionsMenu}
                    size="small"
                    sx={{ ml: 1 }}
                    aria-haspopup="true"
                  >
                    <Iconify icon="entypo:dots-three-vertical" width={24} height={24} />
                  </IconButton>
                </Tooltip>
                <Menu
                  anchorEl={anchorElActions}
                  open={Boolean(anchorElActions)}
                  onClose={handleCloseActionsMenu}
                >
                  {actionItems.map((item) => (
                    <MenuItem key={item.label} onClick={item.action}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Menu>
              </Grid>
            </Grid>
    
          </Toolbar>
        </Card>
      </Grid>
    
      <Grid container sx={{ mt: isMobile ? 10 : 8  }} spacing={2}>
      {/* <Grid container sx={{ mt: 1 }} spacing={2}> */}
        <Grid item xs={12} md={12} sx={{ ml: 0 }}>
          <Card sx={{ ml: 2, mr: 2, p: 2 }}>
            <Grid container direction={isMobile ? "column" : "row"}>
              <Grid item xs={isMobile ? 12 : 9} sx={{ mt: 1, mb: 2 }}>
                <h3 id='' className=''>Interview Information</h3>
              </Grid>
              <Grid item xs={ isMobile ? 12 : 3 } style={{ textAlign: 'center', width : isMobile ? "100%" : " " }}>
                <Button
                  component={RouterLink}
                  to= {`/dashboard/interviews/edit-interview/${id}`}
                  variant="contained"
                  style={{ textTransform: 'capitalize', width : isMobile ? "100%" : " " }}
                >
                   Edit Interview
                </Button>
              </Grid>
            </Grid>
            <Divider />
            <Grid container sx={{ mt: 4 }} spacing={2}>
              <Grid item md={6} xs={12} sx={{ mb: 2 }}>
                <Typography variant="body1">
                  <Grid container>
                    <Grid item md={4} xs={5}>
                      <strong style={{ textAlign: 'right' }}>Title:</strong>
                    </Grid>
                    <Grid item md={7} xs={7}>
                      <Box component="span" sx={{ ml: 1}}>
                        {interviewData?.data?.title || ''}
                      </Box>
                    <Divider flexItem />
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>
              <Divider flexItem />
              <Grid item md={6} xs={12} sx={{ mb: 2 }}>
                <Typography variant="body1">
                  <Grid container>
                    <Grid item md={4} xs={5}>
                      <strong style={{ textAlign: 'right' }}>Candidate Name:</strong>
                    </Grid>
                    <Grid item md={7} xs={7}>
                      <Box component="span" sx={{ ml: 1 }}>
                        {`${interviewData?.data?.candidate?.first_name || ''} ${interviewData?.data?.candidate?.last_name || ''}`}
                      </Box>
                      <Divider flexItem />
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>
              <Divider flexItem />
              <Grid item md={6} xs={12} sx={{ mb: 2 }}>
                <Typography variant="body1">
                  <Grid container>
                    <Grid item md={4} xs={5}>
                      <strong style={{ textAlign: 'right' }}>Posting Title:</strong>
                    </Grid>
                    <Grid item md={7} xs={7}>
                      <Box component="span" sx={{ ml: 1 }}>
                        {interviewData?.data?.job?.title || ''}
                      </Box>
                      <Divider flexItem />
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>
              <Divider flexItem />
              <Grid item md={6} xs={12} sx={{ mb: 2 }}>
                <Typography variant="body1">
                  <Grid container>
                    <Grid item md={4} xs={5}>
                      <strong style={{ textAlign: 'right' }}>Interview Type:</strong>
                    </Grid>
                    <Grid item md={7} xs={7}>
                      <Box component="span" sx={{ ml: 1 }}>
                        {interviewData?.data?.type || ''}
                      </Box>
                      <Divider flexItem />
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>
              <Divider flexItem />
              <Grid item md={6} xs={12} sx={{ mb: 2 }}>
                <Typography variant="body1">
                  <Grid container>
                    <Grid item md={4} xs={5}>
                      <strong style={{ textAlign: 'right' }}>Email Subject:</strong>
                    </Grid>
                    <Grid item md={7} xs={7}>
                      <Box component="span" sx={{ ml: 1 }}>
                        {interviewData?.data?.email_sub || ''}
                      </Box>
                      <Divider flexItem />
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>
              <Divider flexItem />
              <Grid item md={6} xs={12} sx={{ mb: 2 }}>
                <Typography variant="body1">
                  <Grid container>
                    <Grid item md={4} xs={5}>
                      <strong style={{ textAlign: 'right' }}>Start Time:</strong>
                    </Grid>
                    <Grid item md={7} xs={7}>
                      <Box component="span" sx={{ ml: 1 }}>
                      {interviewData?.data?.time_start || ''}
                      </Box>
                      <Divider flexItem />
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>
              <Divider flexItem />
              <Grid item md={6} xs={12} sx={{ mb: 2 }}>
                <Typography variant="body1">
                  <Grid container>
                    <Grid item md={4} xs={5}>
                      <strong style={{ textAlign: 'right' }}>Date:</strong>
                    </Grid>
                    <Grid item md={7} xs={7}>
                      <Box component="span" sx={{ ml: 1 }}>
                        {interviewData?.data?.date || ''}
                      </Box>
                      <Divider flexItem />
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>
              <Divider flexItem />
              <Grid item md={6} xs={12} sx={{ mb: 2 }}>    
                <Typography variant="body1">
                  <Grid container>
                    <Grid item md={4} xs={5}>
                      <strong style={{ textAlign: 'right' }}>End Time:</strong>
                    </Grid>
                    <Grid item md={7} xs={7}>
                      <Box component="span" sx={{ ml: 1 }}>
                      {interviewData?.data?.time_end || ''}
                      </Box>
                      <Divider flexItem />
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>
              <Divider flexItem />
              <Grid item md={6} xs={12} sx={{ mb: 2 }}>
                <Typography variant="body1">
                  <Grid container>
                    <Grid item md={4} xs={5}>
                      <strong style={{ textAlign: 'right' }}>Location:</strong>
                    </Grid>
                    <Grid item md={7} xs={7}>
                      <Box component="span" sx={{ ml: 1 }}>
                        {getLocationName(interviewData?.data?.location) || ''}
                      </Box>
                      <Divider flexItem />
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>
              <Divider flexItem />
              <Grid item md={6} xs={12} sx={{ mb: 2 }}>
                <Typography variant="body1">
                  <Grid container>
                    <Grid item md={4} xs={5}>
                      <strong style={{ textAlign: 'right' }}>Interviewer(s):</strong>
                    </Grid>
                    <Grid item md={7} xs={7}>
                      <Box component="span" sx={{ ml: 1 }}>
                        {interviewData?.data?.interviewers?.length > 0 ? (
                          <List>
                            {interviewData.data.interviewers.map(interviewer => (
                              <ListItem key={interviewer.id}>
                                <ListItemText
                                  primary={interviewer.name}
                                  secondary={`${interviewer.email} • ${interviewer.mobile}`}
                                />
                              </ListItem>
                            ))}
                          </List>
                        ) : (
                          'No interviewers assigned'
                        )}
                      </Box>
                      <Divider flexItem />
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>
              <Divider flexItem />
              <Grid item md={11.5} xs={12} sx={{ mb: 2 }}>
                <strong style={{ textAlign: 'center' }}>Email Body</strong>
                {/* <InputLabel title='Job Description'>Job Description</InputLabel> */}
                <div
                  dangerouslySetInnerHTML={{ __html: interviewData?.data?.email_msg || '' }}
                  style={{ padding: '20px', border: '1px solid #ccc', borderRadius: '4px' }}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
      
      <Dialog
        open={openDeleteModel}
        onClose={handleDeleteClose}
        aria-labelledby="responsive-dialog-title"
        sx={{ '& .MuiDialog-paper': { height: '200px' } }}
      >
        <DialogTitle>{'Delete'}</DialogTitle>
        <DialogContent>
        <DialogContentText>Are you sure you want to delete this Interview?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose}>Disagree</Button>
          <Button onClick={handleDeleteJob} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PriviewInterview;