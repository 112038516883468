import React, { useState, useMemo, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useTheme } from '@mui/material/styles';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  ListItemIcon,
  DialogContent,
  Grid,
} from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {
  useGetEmailTamplateQuery,
  useGetEmailVariableTamplateQuery,
  useDeleteEmailTemplateMutation,
  useUpdateEmailTemplateMutation,
} from '../../../redux/services/settings/EmailTamplateService';
import { useGetEmailCategoryQuery } from '../../../redux/services/settings/EmailCategoryService';
import { sortedDataFn } from '../../../utils/getSortedData';
import { showToast } from '../../../utils/toast';

// components
import EmailModalTemplates from '../../../components/email-templates/EmailModalTemplates';
import Page from '../../../components/Page';
import Label from '../../../components/Label';
import Iconify from '../../../components/Iconify';
import ConfirmationDialog from '../../main/ConfirmationDialog';
// mock

const Templates = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [modalOpen, setModalOpen] = useState(false);
  const [editmodalOpen, setEditModalOpen] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [idsToDelete, setIdsToDelete] = useState([]);
  const { data = [], isLoading, refetch } = useGetEmailTamplateQuery();
  useEffect(() => refetch(), []);
  const { data: categoryData, isLoading: isCategoryLoading } = useGetEmailCategoryQuery();
  const { data: variableData, isLoading: isVariableLoading } = useGetEmailVariableTamplateQuery();
  const [DeleteEmailTemplate, DeleteEmailTemplateInfo] = useDeleteEmailTemplateMutation();
  const [currentIndex, setCurrentIndex] = useState(null);
  const [modalName, setModalName] = useState('add');

  const [editValue, setEditValue] = useState();

  const modalHandleClose = (value) => {
    setModalOpen(value);
    setEditModalOpen(value);
    refetch();
  };

  // Show Data In Table
  const [active, setActive] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const handleeClosee = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setActive(false);
  };
  const handleClosee = () => {
    setOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const sortData = useMemo(() => {
    const sortresult = sortedDataFn(data.data);
    return sortresult;
  });

  const navigateCancel = () => {
    navigate('/dashboard/InstituteSettings/settings');
  };

  const addNewTemplatesHandler = () => {
    setModalOpen(true);
  };

  const onEditModalHandler = (dataIndex) => {
    setEditModalOpen(true);
    const dataArr = sortData;
    const currentDataObj = dataArr[dataIndex];
    setEditValue(currentDataObj);
    setModalName('Edit');
  };

  /// delete email template
  const onEmailTemplateDeleteHandler = async (dataIndex) => {
    setOpen(false);
    setActive(true);
    setCurrentIndex(dataIndex);
    const dataArr = sortData;
    const currentDataObj = dataArr[dataIndex];
    await DeleteEmailTemplate(currentDataObj.id);
  };
  useEffect(() => {
    if (DeleteEmailTemplateInfo.isSuccess) {
      showToast('success', DeleteEmailTemplateInfo.data.msg);
      DeleteEmailTemplateInfo.reset();
      refetch();
    }
    if (DeleteEmailTemplateInfo.isError) {
      showToast('error', DeleteEmailTemplateInfo.error.data.msg);
      DeleteEmailTemplateInfo.reset();
      refetch();
    }
  }, [DeleteEmailTemplateInfo, refetch, categoryData]);

  const getTypeLabel = (type) => {
    switch (type) {
      case 'C':
        return 'Candidate';
      case 'I':
        return 'Internal';
      default:
        return 'Unknown'; // Fallback for any other types
    }
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' };
    return new Date(dateString).toLocaleString(undefined, options);
  };
  
  // Assuming you have a data array that includes the created and updated fields
  const formattedData = data?.data?.map(item => ({
    ...item,
    created: formatDate(item.created), 
    updated: formatDate(item.updated),
    type: getTypeLabel(item.type),
  }));

  const columns = [
    {
      name: 'id',
      label: 'id',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({ style: { maxWidth: '250px' } }),
      },
    },
    {
      name: 'category_name',
      label: 'Category Name',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({ style: { maxWidth: '250px' } }),
      },
    },
    {
      name: 'subject',
      label: 'Subject',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'type',
      label: 'Type',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'created',
      label: 'Created Date',
      options: {
        filter: true,
        sort: true,
        sortDirection: 'desc',
      },
    },
    {
      name: 'updated',
      label: 'Modify Date',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'action',
      label: 'Action',
      options: {
        filter: false,
        sort: false,
        customBodyRenderLite: (dataIndex) => {
          const rowData = sortData[dataIndex];
          const isDisabled = rowData?.company === null && data.role !== "superuser";

          return (
          <>
            <Snackbar
              open={open}
              autoHideDuration={3000}
              onClose={handleeClosee}
              sx={{ height: '10vh' }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
                position: 'absolute',
              }}
            >
              <Alert onClose={handleeClosee} severity="success" sx={{ width: '100%' }}>
                Interview Deleted!
              </Alert>
            </Snackbar>
            <div>
              <Dialog
                open={open}
                onClose={handleClosee}
                aria-labelledby="responsive-dialog-title"
                sx={{ '& .MuiDialog-paper': { minHeight: '200px' } }}
              >
                <DialogTitle variant="h4">{'Delete'}</DialogTitle>
                <DialogContent>
                  <DialogContentText>Are you sure you wan&#39;t to delete this template?</DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button autoFocus onClick={handleClosee}>
                    Disagree
                  </Button>
                  <Button
                    onClick={() => onEmailTemplateDeleteHandler(dataIndex)}
                    loading={dataIndex === currentIndex ? useDeleteEmailTemplateMutation.isLoading : false}
                    autoFocus
                  >
                    Agree
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
            <Button
              style={{ minWidth: 0 }}
              onClick={() => navigate(`/dashboard/updateEmail/${sortData[dataIndex]?.id}`)}
              disabled={isDisabled}
            >
              <ListItemIcon style={{ color: '#fff', padding: '0px', minWidth: 0 }}>
                <Iconify icon="ep:edit" width={24} height={24} color={isDisabled ? "lightgrey" :'blue'} />
              </ListItemIcon>
            </Button>
            <LoadingButton
              style={{ minWidth: 0, margin: '0px 5px' }}
              // variant="contained"
              color="error"
              onClick={handleClickOpen}
              // onClick={() => onEmailTemplateDeleteHandler(dataIndex)}
              loading={dataIndex === currentIndex ? useDeleteEmailTemplateMutation.isLoading : false}
              disabled={isDisabled}
            >
              <ListItemIcon style={{ color: '#fff', padding: '0px', minWidth: 0 }}>
                <Iconify icon="eva:trash-2-outline" width={24} height={24} color={isDisabled ? "lightgrey" : 'red'} />
              </ListItemIcon>
            </LoadingButton>
          </>
          )
        },
      },
    },
  ];
  const labelStatus = (
    <Label variant="ghost" color={'success'}>
      {sentenceCase('active')}
    </Label>
  );
  const editAndDeleteButton = (
    <>
      <Button onClick={onEditModalHandler}>
        <ListItemIcon style={{ justifyContent: 'center' }}>
          <Iconify icon="eva:edit-fill" width={24} height={24} />
        </ListItemIcon>
      </Button>
      <Button>
        <ListItemIcon style={{ justifyContent: 'center' }}>
          <Iconify icon="eva:trash-2-outline" width={24} height={24} />
        </ListItemIcon>
      </Button>
    </>
  );

  const handleDeleteSelected = async () => {    
    try {
      await Promise.all(idsToDelete.map(id => DeleteEmailTemplate(id)));
      showToast('success', 'Selected Templates deleted successfully');
      refetch();
    } catch (error) {
      showToast('error', 'Failed to delete selected Templates');
    } finally {
      setOpenDeleteDialog(false);
      setIdsToDelete([]);
    }
  };

  const options = {
    filterType: 'dropdown',
    responsive: 'standard', // or 'vertical', 'simple'
    filter: true,
    download: true,
    print: true,
    // selectableRows: 'multiple',
    rowsPerPage: 10, // Adjust as needed
    rowsPerPageOptions: [10, 20, 30], // Adjust as needed
    // customToolbarSelect: () => {}, // Customize toolbar if needed
    viewColumns: true,
    onRowsDelete: (rowsDeleted) => {
      const ids = rowsDeleted.data.map(row => sortData[row.dataIndex].id);
      setIdsToDelete(ids);
      setOpenDeleteDialog(true);
    },
  };  

  const getInputValue = (value) => {
    console.log('value', value);
  };

  return (
    <Page title="User">
      <Container>
        <Stack direction={isMobile ? "column" : "row"} alignItems="center" justifyContent="space-between" mb={5} style={{ paddingLeft: "0px" }} sx={{ marginBottom:'0px' }}>
          <Container
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              paddingLeft: "0px",
            }}
          >
            <div className="backbutton tt-back" style={{ display: 'flex' }}>
              <ArrowBackIosIcon
                onClick={navigateCancel}
                sx={{
                  cursor: 'pointer',
                }}
              />
            </div>
            {/* <h1>Templates</h1> */}
            <Typography 
              variant="h3" 
              color="textPrimary" 
              sx={{ 
                width: '300px',
                textDecorationLine: 'underline',
                backgroundColor: 'transparent !important'
              }}
            >
              Templates
            </Typography>
          </Container>
          <Button
            style={{ width: isMobile ? "100%" : "250px", height: "46px", marginTop: isMobile ? "10px" : "", marginBottom: isMobile ? "10px" : "" }}
            variant="contained"
            component={RouterLink}
            to="/dashboard/createEmail"
            // onClick={addNewTemplatesHandler}
            startIcon={
              <Iconify
                icon="eva:plus-fill"
                sx={{
                  height: '35px',
                }}
              />
            }
          >
            Create Template
          </Button>
        </Stack>
        <Grid item xs={12} md={12}>
          <Card>
            <TableContainer>
              <MUIDataTable title={'Templates List'} data={formattedData} columns={columns} options={options} />
            </TableContainer>
          </Card>
        </Grid>
      </Container>
      <EmailModalTemplates
        open={modalOpen}
        handleClose={modalHandleClose}
        label="Add List"
        type="text"
        textBoxLabel="List Name"
        id="listName"
        name="list"
        autocomplete="off"
        getInputValue={getInputValue}
        buttonLabel="Add List"
        categoryData={categoryData?.data}
        variableData={variableData?.data}
      />
      <EmailModalTemplates
        open={editmodalOpen}
        handleClose={modalHandleClose}
        label="Edit List"
        type="text"
        textBoxLabel="List Name"
        id="editListName"
        name="list"
        getInputValue={getInputValue}
        buttonLabel="Update List"
        categoryData={categoryData?.data}
        variableData={variableData?.data}
        emailTemplateData={editValue}
      />
      <ConfirmationDialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        title="Delete"
        message="Are you sure you want to delete the selected Templates?"
        onConfirm={handleDeleteSelected}
      />
    </Page>
  );
};

export default Templates;
