import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { skipToken } from '@reduxjs/toolkit/query/react';
import {
  Card,
  Box,
  Stack,
  Button,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Autocomplete,
  FormHelperText,
} from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import ImagePreview from '../../../components/imagePreview/ImagePreview';
import { useGetCountryQuery, useGetStateQuery, useGetCityQuery } from '../../../redux/services/settings/CountryStateCityService';
import { useAddCompanyInfoMutation } from '../../../redux/services/settings/CareerSiteService';
import { showToast } from '../../../utils/toast';

function AddCompany() {
  const [countryId, setCountryId] = useState(skipToken);
  const [stateId, setStateId] = useState(skipToken);
  const { data: stateData, refetchState } = useGetStateQuery(countryId);
  const { data: countryData, refetch } = useGetCountryQuery();
  const { data: cityData, refetch: refectCity } = useGetCityQuery(stateId);
  const [addCompany, addCompanyInfo] = useAddCompanyInfoMutation();
  const [companyData, setCompanyData] = useState({})
  const [errors, setErrors] = useState({});
  const navigate = useNavigate()
  const [phoneErrorMessage, setPhoneErrorMessage] = useState('');

  const onInputChangeHandler = (e) => {
    // console.log(e.target.name);
    // console.log(e.target.value);
    if (e.target.name === "country") {
      setCountryId(e.target.value)
      setCompanyData({ ...companyData, [e.target.name]: e.target.value });
      refetchState()
      //   setFieldValue({ ...fieldValue, [e.target.name]: e.target.value });
    }
    if (e.target.name === "state") {
      setStateId(e.target.value)
      setCompanyData({ ...companyData, [e.target.name]: e.target.value });
      refectCity()
      //   setFieldValue({ ...fieldValue, [e.target.name]: e.target.value });
    }
    setCompanyData({ ...companyData, [e.target.name]: e.target.value });
  };

  const companyLogoChangeHandler = async (file) => {
    setCompanyData({ ...companyData, logo: file });
  }

  const validate = () => {
    const newErrors = {};
    if (!companyData.name) newErrors.name = "Company Name is required";
    if (!companyData.domain) newErrors.domain = "Company Domain is required";
    if (!companyData.link) newErrors.link = "Website Link is required";
    if (!companyData.address) newErrors.address = "Address is required";
    if (!companyData.phone) newErrors.phone = "Phone is required";
    if (!/^\d+$/.test(companyData.phone)) newErrors.phone = "Phone must be a number";
    if (!companyData.email) newErrors.email = "Email is required";
    if (!/\S+@\S+\.\S+/.test(companyData.email)) newErrors.email = "Email is invalid";
    if (!companyData.pincode) newErrors.pincode = "Pincode is required";
    if (!/^\d+$/.test(companyData.pincode)) newErrors.pincode = "Pincode must be a number";
    if (!companyData.landmark) newErrors.landmark = "Landmark is required";
    if (!companyData.city) newErrors.city = "City is required";
    if (!companyData.description) newErrors.description = "Description is required";
    if (!companyData.logo) newErrors.logo = "Logo is required";
    if (!companyData.country) newErrors.country = "Country is required";
    if (!companyData.state) newErrors.state = "State is required";
    if (!companyData.city) newErrors.city = "City is required";
    return newErrors;
  };

  const onSubmit = async() => {
    const newErrors = validate();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    const auth = JSON.parse(localStorage.getItem("globalUser"))
    const formData = new FormData();
    formData.append('admin', auth.account.account_id);
    formData.append('company', companyData.name);
    formData.append('domain', companyData.domain);
    formData.append('website', companyData.link);
    formData.append('address', companyData.address);
    formData.append('phone', companyData.phone);
    formData.append('email', companyData.email);
    formData.append('landmark', companyData.landmark);
    formData.append('city', companyData.city);
    formData.append('pincode', companyData.pincode);
    formData.append('logo', companyData.logo);
    formData.append('description', companyData.description);
    // formData.append('tag', companyData.tag);
    await addCompany(formData);
  }

  useEffect(()=>{
    if (addCompanyInfo.isSuccess){
      toast.success('Company added successfully');
      navigate("/dashboard/InstituteSettings/settings")
    }
    if (addCompanyInfo.isError){
      toast.error(`${addCompanyInfo.error.data.msg}`);
    }

  },[addCompanyInfo])

  useEffect(() => {
    refetch()
  }, [])

  return (
    <>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} md={6}>
        <TextField
            margin="dense"
            variant="outlined"
            fullWidth
            name="name"
            label="Company Name"
            onChange={onInputChangeHandler}
            error={!!errors.name}
            helperText={errors.name}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            margin="dense"
            variant="outlined"
            fullWidth
            name="domain"
            label="Company Domain"
            onChange={onInputChangeHandler}
            error={!!errors.domain}
            helperText={errors.domain}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            margin="dense"
            variant="outlined"
            fullWidth
            name="link"
            label="Website Link"
            onChange={onInputChangeHandler}
            error={!!errors.link}
            helperText={errors.link}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            margin="dense"
            variant="outlined"
            fullWidth
            name="address"
            label="Address"
            onChange={onInputChangeHandler}
            error={!!errors.address}
            helperText={errors.address}
          />
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <TextField
            type="number"
            margin="dense"
            variant="outlined"
            fullWidth
            name="phone"
            label="Phone"
            onChange={onInputChangeHandler}
            error={!!errors.phone}
            helperText={errors.phone}
            inputProps={{ pattern: '[0-9]*' }}
          />
        </Grid> */}
        
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <PhoneInput
              specialLabel="Company Mobile Number"
              country={'in'}
              value={companyData.phone || ''}
              onChange={(value) => {
                if (value.match(/^\+?[\d\s-]+$/)) {
                  setCompanyData({ ...companyData, phone: value });
                  setPhoneErrorMessage('');
                }
              }}
              inputStyle={{
                width: '100%',
                height: '56px',
                fontSize: '16px',
                borderRadius: '4px',
                border: '1px solid #ccc',
                padding: '0 14px',
                paddingLeft: '60px',
              }}
              containerStyle={{
                width: '100%',
                position: 'relative'
              }}
              buttonStyle={{
                border: '1px solid #ccc',
                borderRight: 'none',
                backgroundColor: '#fff'
              }}
              inputProps={{
                required: true,
                onBlur: () => setPhoneErrorMessage('')
              }}
            />
            {(phoneErrorMessage || errors.phone) && (
              <FormHelperText error>
                {phoneErrorMessage || errors.phone}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            margin="dense"
            variant="outlined"
            fullWidth
            name="email"
            label="Email"
            onChange={onInputChangeHandler}
            error={!!errors.email}
            helperText={errors.email}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            type="number"
            margin="dense"
            variant="outlined"
            fullWidth
            name="pincode"
            label="Pincode"
            onChange={onInputChangeHandler}
            error={!!errors.pincode}
            helperText={errors.pincode}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            margin="dense"
            variant="outlined"
            fullWidth
            name="landmark"
            label="Landmark"
            onChange={onInputChangeHandler}
            error={!!errors.landmark}
            helperText={errors.landmark}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl variant="standard" sx={{ m: 1, minWidth: '100%' }}>
            <Autocomplete
              id="country-autocomplete"
              options={countryData?.countries || []}
              getOptionLabel={(option) => option.name || ''}
              value={countryData?.countries?.find(item => item.id === companyData.country) || null}
              onChange={(event, newValue) => {
                onInputChangeHandler({ target: { name: 'country', value: newValue?.id } });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Select country"
                  placeholder="Select the Country"
                />
              )}
              ListboxProps={{
                style: {
                  maxHeight: '200px',
                  overflow: 'auto',
                },
              }}
            />
            {errors.country && <span style={{ color: 'red' }}>{errors.country}</span>}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl variant="standard" sx={{ m: 1, minWidth: '100%' }}>
            <Autocomplete
              id="state-autocomplete"
              options={stateData?.states || []}
              getOptionLabel={(option) => option.name || ''}
              value={stateData?.states?.find(item => item.id === companyData.state) || null}
              onChange={(event, newValue) => {
                onInputChangeHandler({ target: { name: 'state', value: newValue?.id } });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="State"
                  placeholder="Select the State"
                />
              )}
              ListboxProps={{
                style: {
                  maxHeight: '200px',
                  overflow: 'auto',
                },
              }}
            />
            {errors.state && <span style={{ color: 'red' }}>{errors.state}</span>}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl variant="standard" sx={{ m: 1, minWidth: '100%' }}>
            <Autocomplete
              id="city-autocomplete"
              options={cityData?.cities || []}
              getOptionLabel={(option) => option.name || ''}
              value={cityData?.cities?.find(item => item.id === companyData.city) || null}
              onChange={(event, newValue) => {
                onInputChangeHandler({ target: { name: 'city', value: newValue?.id } });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Select City"
                  placeholder="Select the City"
                />
              )}
              ListboxProps={{
                style: {
                  maxHeight: '200px',
                  overflow: 'auto',
                },
              }}
            />
            {errors.city && <span style={{ color: 'red' }}>{errors.city}</span>}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Stack>
            <ImagePreview
              name='logo'
              // logo={companyData.logo}
              onFileSelectSuccess={(file, e) => companyLogoChangeHandler(file)}
              onFileSelectError={({ error }) => showToast("error", error)}
            />
            {errors.logo && <span style={{ color: 'red' }}>{errors.logo}</span>}
          </Stack>
        </Grid>
        <Grid item xs={12} md={7}>
          <TextField
            multiline
            rows={10}
            margin="dense"
            variant="outlined"
            fullWidth
            label="About the Institute"
            name="description"
            onChange={onInputChangeHandler}
            error={!!errors.description}
            helperText={errors.description}
          />
        </Grid>
        <Grid item xs={12} md={7} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button variant='contained' onClick={onSubmit}>Add Company</Button>
        </Grid>
      </Grid>
    </>
  )
}

export default AddCompany