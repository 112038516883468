import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormControlLabel,
  Checkbox,
  Grid,
  Box,
  useMediaQuery,
} from '@mui/material';
import { showToast } from '../../utils/toast';
import {
  useEmailSettingSmtpDataMutation,
  useGetEmailCredentialsQuery,
  useUpdateEmailSettingSmtpDataMutation,
} from '../../redux/services/candidate/CandidateServices';
import EmailSmtpDocumentationModal from './EmailSmtpDocumentationModal';

const EmailCommunicationModal = ({ open, onClose, editData, refetch }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [senderMail, setSenderMail] = useState('');
  const [authPassword, setAuthPassword] = useState('');
  const [emailBackend, setEmailBackend] = useState('django_smtp_ssl.SSLEmailBackend');
  const [emailHost, setEmailHost] = useState('');
  const [emailPort, setEmailPort] = useState('');
  const [emailTls, setEmailTls] = useState(false);
  const [emailSsl, setEmailSsl] = useState(false);
  const [emailProvider, setEmailProvider] = useState('');
  const [isDocumentationOpen, setIsDocumentationOpen] = useState(false);
  const [sendEmailSettingSmtpData] = useEmailSettingSmtpDataMutation();
  const [updateEmailSettingSmtpData] = useUpdateEmailSettingSmtpDataMutation();
  const { data: emailCredentialsData, refetch: refetchEmailCredentials } = useGetEmailCredentialsQuery();

  useEffect(() => {
    if (editData) {
      setSenderMail(editData.sender_mail);
      setAuthPassword(editData.auth_password);
      setEmailBackend(editData.email_backend);
      setEmailHost(editData.email_host);
      setEmailPort(editData.email_port);
      setEmailTls(editData.email_tls);
      setEmailSsl(editData.email_ssl);
      setEmailProvider('');
    } else {
      setSenderMail('');
      setAuthPassword('');
      setEmailBackend('django_smtp_ssl.SSLEmailBackend');
      setEmailHost('');
      setEmailPort('');
      setEmailTls(false);
      setEmailSsl(false);
      setEmailProvider('');
    }
  }, [editData]);

  useEffect(() => {
    refetchEmailCredentials();
  }, [])

  const handleSendEmail = async () => {
    try {
      if (editData) {
        await updateEmailSettingSmtpData({
          id: editData.id,
          sender_mail: senderMail,
          auth_password: authPassword,
          email_backend: emailBackend,
          email_host: emailHost,
          email_port: emailPort,
          email_tls: emailTls,
          email_ssl: emailSsl,
        }).unwrap();
        showToast("success", "Email settings updated successfully!");
      } else {
        await sendEmailSettingSmtpData({
          sender_mail: senderMail,
          auth_password: authPassword,
          email_backend: emailBackend,
          email_host: emailHost,
          email_port: emailPort,
          email_tls: emailTls,
          email_ssl: emailSsl, 
        }).unwrap();
        showToast("success", "Email settings saved successfully!");
      }
      setSenderMail('');
      setAuthPassword('');
      setEmailBackend('django_smtp_ssl.SSLEmailBackend');
      setEmailHost('');
      setEmailPort('');
      setEmailTls(false);
      setEmailSsl(false);
      setEmailProvider('');
      refetch(); 
      onClose();
    } catch (error) {
      console.error('Failed to send email settings:', error);
      showToast("error", "Failed to save email settings.");
    }
  };

  const handleProviderChange = (event) => { // Updated function to handle provider change
    const providerId = event.target.value;
    setEmailProvider(providerId);
    const selectedProvider = emailCredentialsData?.data?.find(item => item.id === providerId);
    
    emailCredentialsData?.data?.forEach((item) => {
      if (item.id === providerId) {
        setEmailHost(item.Email_host);
        setEmailPort(item.Email_port);
        setEmailTls(item.Email_use_tls);
        setEmailSsl(item.Email_use_ssl);
      }
    });
  };

  const handleOpenDocumentation = () => {
    setIsDocumentationOpen(true);
  };

  const handleCloseDocumentation = () => {
    setIsDocumentationOpen(false);
  };

  return (
    <>
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm" aria-labelledby="alertmodalCloseHandler-dialog-title" aria-describedby="alert-dialog-description" 
    sx={{
      '& .MuiDialog-paper': {
        marginTop: { xs: '10%', sm: '7%' }, // Adjust margin for smaller screens
        maxHeight: '80vh', // Ensure the modal does not exceed the viewport height
        overflowY: 'auto', // Allow scrolling if content overflows
      },
    }}>
      <div>
        <DialogTitle>Email SMTP</DialogTitle>
        <DialogContent>
          <Box sx={{ flexGrow: 1, padding: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth margin="dense">
                <InputLabel id="email_provider_label">Email Provider</InputLabel> {/* Add InputLabel */}
                <Select
                  labelId="email_provider_label" // Associate InputLabel with Select
                  id="email_provider"
                  value={emailProvider}
                  onChange={handleProviderChange} // Handle provider change
                  variant="standard"
                >
                  {emailCredentialsData?.data?.map((item) => (
                      <MenuItem  key={item.id} value={item.id}>{item.Name}</MenuItem>
                  ))}
                </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  autoFocus
                  margin="dense"
                  id="sender_mail"
                  label="Email"
                  type="email"
                  fullWidth
                  variant="standard"
                  value={senderMail}
                  onChange={(e) => setSenderMail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  margin="dense"
                  id="auth_password"
                  label="Password"
                  type="password"
                  fullWidth
                  variant="standard"
                  value={authPassword}
                  onChange={(e) => setAuthPassword(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  margin="dense"
                  id="email_backend"
                  label="Email Backend"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={emailBackend}
                  onChange={(e) => setEmailBackend(e.target.value)}
                  style={{ display: 'none' }} 
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  margin="dense"
                  id="email_host"
                  label="Email Host"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={emailHost}
                  onChange={(e) => setEmailHost(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  margin="dense"
                  id="email_port"
                  label="Email Port"
                  type="number"
                  fullWidth
                  variant="standard"
                  value={emailPort}
                  onChange={(e) => setEmailPort(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={emailTls}
                      onChange={(e) => setEmailTls(e.target.checked)}
                      color="primary"
                    />
                    }
                  label="Email TLS"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={emailSsl}
                      onChange={(e) => setEmailSsl(e.target.checked)}
                      color="primary"
                    />
                  }
                  label="Email SSL"
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </div>
      <DialogActions  style={{position: "relative"}}>
        <Grid container spacing={1} justifyContent="flex-end">
          <Grid item xs={12} sm="auto">
            <Button onClick={handleOpenDocumentation} autoFocus variant="outlined" fullWidth>Email SMTP Documentation</Button>
          </Grid>
          <Grid item xs={12} sm="auto">
            <Button onClick={onClose} autoFocus variant="outlined" fullWidth style={{ marginLeft: isMobile ? '0' : '8px'}}>Cancel</Button>
          </Grid>
          <Grid item xs={12} sm="auto">
            <Button onClick={handleSendEmail} variant="contained" fullWidth style={{ marginLeft: isMobile ? '0' : '8px'}}>{editData ? 'Update' : 'Send'}</Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
    <EmailSmtpDocumentationModal open={isDocumentationOpen} onClose={handleCloseDocumentation} />
    </>
  );
};

export default EmailCommunicationModal;