import React, { useEffect, useState, useMemo } from 'react';
import MUIDataTable from 'mui-datatables';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
// eslint-disable-next-line import/no-unresolved
import { sortedDataFn } from 'src/utils/getSortedData';

// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  ListItemIcon,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
} from '@mui/material';
// components
import Page from '../../../components/Page';
import Label from '../../../components/Label';
import Iconify from '../../../components/Iconify';
import { 
  useGetcompanyUsersApiQuery,
  useUpdateUserApiMutation,
  useDeleteUserApiMutation
} from '../../../redux/services/settings/UserService';
import { showToast } from '../../../utils/toast';
import ConfirmationDialog from '../../main/ConfirmationDialog';

// mock

const Approvals = () => {
  const [currentIndex, setCurrentIndex] = useState(null)
  const [UpdateUserApi, UpdateUserApiInfo] = useUpdateUserApiMutation();
  const [DeleteUserApi, DeleteUserApiInfo] = useDeleteUserApiMutation();
  const {data: userData, refetch} = useGetcompanyUsersApiQuery()
  const data = userData?.list
  const [modalOpen, setModalOpen] = useState(false);
  const [editmodalOpen, setEditModalOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [idsToDelete, setIdsToDelete] = useState([]);
  const sortedData = useMemo(() => {
    const result = sortedDataFn(data?.list);
    return result;
  }, [data]);

  const modalHandleClose = (value) => {
    setModalOpen(value);
    setEditModalOpen(value);
  };

  const addNewApprovalsHandler = async (accountId) => {
    try {
      const response = await UpdateUserApi({
        status: "A",
        account_id: accountId,
      });
  
      if (response?.data?.code === 200) {
        showToast('success', response.data.msg || 'Account activated successfully!');
      } else {
        showToast('error', response.error.data.msg || 'Failed to activate account.');
      }
      refetch();
    } catch (error) {
      showToast('error', 'Failed to activate account.');
    }
  };

  useEffect(() => {
    refetch()
  }, [refetch])

  const handleDialogOpen = (accountId) => {
    setSelectedAccountId(accountId);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedAccountId(null);
  };

  const handleDeleteConfirm = async () => {
    if (selectedAccountId !== null) {
      await onDeleteHandler(selectedAccountId);
    }
    handleDialogClose();
  };

  const onDeleteHandler = async (accountId) => {
    await DeleteUserApi(accountId);
    refetch();
  };
  if (DeleteUserApiInfo.isSuccess) {
    showToast('success', DeleteUserApiInfo.data.msg);
    DeleteUserApiInfo.reset();
  }
  if (DeleteUserApiInfo.isError) {
    showToast('error', DeleteUserApiInfo.error.data.msg);
    DeleteUserApiInfo.reset();
  }

  const onEditModalHandler = () => {
    setEditModalOpen(true);
  };
  const columns = [
    {
      name: 'first_name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({ style: { maxWidth: '250px' } }),
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({ style: { maxWidth: '250px' } }),
      },
    },
    {
      name: 'mobile',
      label: 'Phone',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'account_id',
      label: 'account_id',
      options: {
        filter: false,
        sort: false,
        viewColumns: false,
        display: false,
        hide:true,
      },
      show: false,
      hide: true,
    },
    {
      name: 'department',
      label: 'Department',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'verified',
      label: 'Verified',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => value ? 'Yes' : 'No',
        },
    },
    {
      name: 'action',
      label: 'Action',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value,tableMeta) => {
          const accountId = tableMeta.rowData[3];
          const isVerified = tableMeta.rowData[5];
          return (
            <>
              {isVerified ? (
                <Button
                  style={{ minWidth: 0, color: "#fff" }}
                  variant="contained"
                  color="primary"
                  disabled
                >
                  Verified
                </Button>
              ) : (
                <Button
                  style={{ minWidth: 0, color: "#fff" }}
                  variant="contained"
                  color="success"
                  onClick={() => addNewApprovalsHandler(accountId)}
                >
                  Approve
                </Button>
              )}
              <LoadingButton
                style={{ minWidth: 0, margin: '0px 5px' }}
                variant="contained"
                color="error"
                onClick={() => handleDialogOpen(accountId)}
              >
                <ListItemIcon style={{ color: '#fff', padding: '0px', minWidth: 0 }}>
                  <Iconify icon="eva:trash-2-outline" width={24} height={24} />
                </ListItemIcon>
              </LoadingButton>
            </>
          );
        }
      },
      
    },
  ];
  const labelStatus = (
    <Label variant="ghost" color={'success'}>
      {sentenceCase('active')}
    </Label>
  );
  const editAndDeleteButton = (
    <>
      <Button onClick={onEditModalHandler}>
        <ListItemIcon style={{ justifyContent: 'center' }}>
          <Iconify icon="eva:edit-fill" width={24} height={24} />
        </ListItemIcon>
      </Button>
      <Button>
        <ListItemIcon style={{ justifyContent: 'center' }}>
          <Iconify icon="eva:trash-2-outline" width={24} height={24} />
        </ListItemIcon>
      </Button>
    </>
  );

  const handleDeleteSelected = async () => {    
    try {
      await Promise.all(idsToDelete.map(id => DeleteUserApi(id)));
      showToast('success', 'Selected Users deleted successfully');
      refetch();
    } catch (error) {
      showToast('error', 'Failed to delete selected Users');
    } finally {
      setOpenDeleteDialog(false);
      setIdsToDelete([]);
    }
  };

  const options = {
    filterType: 'dropdown',
    responsive: 'standard', // or 'vertical', 'simple'
    filter: true,
    download: true,
    print: true,
    // selectableRows: 'multiple',
    rowsPerPage: 10, // Adjust as needed
    rowsPerPageOptions: [10, 20, 30], // Adjust as needed
    // customToolbarSelect: () => {}, // Customize toolbar if needed
    viewColumns: true,
    onRowsDelete: (rowsDeleted) => {
      const ids = rowsDeleted.data.map(row => data[row.dataIndex].id);
      setIdsToDelete(ids);
      setOpenDeleteDialog(true);
    },
  };
  

  return (
    <Page title="User">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          {/* <h1>
            Approvals
          </h1> */}
          <Typography 
              variant="h4" 
              color="textPrimary" 
              sx={{ 
                width: '300px',
                textDecorationLine: 'underline',
                backgroundColor: 'transparent !important'
              }}
            >
              Approvals
            </Typography>
        </Stack>
       
        <Grid item xs={12} md={12}>
          <Card>
            <TableContainer>
              <MUIDataTable title={'Approvals List'} data={data} columns={columns} options={options} />
            </TableContainer>
          </Card>
        </Grid>
      </Container>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        sx={{ '& .MuiDialog-paper': { minHeight: '200px' } }} // Adjust the height as needed
      >
        <DialogTitle>Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this approval?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Disagree
          </Button>
          <Button onClick={handleDeleteConfirm} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        title="Delete"
        message="Are you sure you want to delete the selected Users?"
        onConfirm={handleDeleteSelected}
      />
    </Page>
  );
};

export default Approvals;
